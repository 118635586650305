import * as React from 'react'
import clsx from 'clsx'

import DialogBase from '../../../../../../components/DialogBase'
import TrunkrsLogo from '../../../../../../components/TrunkrsLogo'

import styles from './SavingDialog.module.scss'

export interface ISavingDialogProps {
  open: boolean
  saved: number
  total: number
}

const SavingDialog: React.FunctionComponent<ISavingDialogProps> = ({
  open,
  saved,
  total,
}) => (
  <DialogBase open={open}>
    <div className={clsx('modal-content', styles.modal)}>
      <div className={clsx('modal-body', styles.body)}>
        <div className={styles.logoContainer}>
          <TrunkrsLogo className={styles.logo} />
        </div>
        <h6 className={styles.text}>
          {saved} of {total} shipments have been saved...
        </h6>
      </div>
    </div>
  </DialogBase>
)

export default SavingDialog
