import * as React from 'react'

import { MappingNames } from '../Mappings'
import { Configuration } from './typings'

export interface IConfigurationContext {
  isLoading: boolean
  config: Configuration | null
  setField: (field: keyof MappingNames, value: number) => void
  loadConfig: () => Promise<Configuration | null>
  saveConfig: () => Promise<void>
}

export const configurationContextDefaults: IConfigurationContext = {
  isLoading: false,
  config: null,
  setField: () => null,
  loadConfig: () => Promise.reject(new Error('Not implemented.')),
  saveConfig: () => Promise.reject(new Error('Not implemented.')),
}

export const ConfigurationContext = React.createContext<IConfigurationContext>(
  configurationContextDefaults,
)
