/* eslint-disable no-param-reassign,@typescript-eslint/no-explicit-any,@typescript-eslint/no-non-null-assertion */
import { unparse, ParseResult } from 'papaparse'
import axios, { AxiosResponse } from 'axios'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import map from 'lodash/map'
import get from 'lodash/get'
import * as FileSaver from 'file-saver'
import uniqueString from 'unique-string'

import { BASE_URL } from '../../../../constants'
import { getBearerToken } from '../../../../utils/laravel/token'

import { MappingNames } from '../Mappings'
import { IMapping } from '../Mappings/typings'

import { IShipmentSaveResult } from '.'
import { IShipment } from './typings'

type Dictionary = { [key: string]: string }

const findCellValueRaw = (row: any[], index: number): any => row[index]

const findCellValueWithHeaders = (
  row: any,
  columns: string[],
  index: number,
): any => row[columns[index]]

const mergeAddress = (
  addressLine: string,
  houseNumber: string,
  extension: string,
): string => [addressLine, houseNumber, extension].filter(Boolean).join(' ')

const mapRowToShipment = (row: Dictionary): IShipment => {
  const {
    [MappingNames.Address]: address,
    [MappingNames.HouseNr]: houseNr,
    [MappingNames.NrExtension]: extension,
    [MappingNames.Phone]: phoneNumber,
  } = row

  delete row[MappingNames.HouseNr]
  delete row[MappingNames.NrExtension]

  if (phoneNumber) {
    row[MappingNames.Phone] =
      Number(phoneNumber) === 0 ? '' : phoneNumber.replace('-', '')
  }

  row[MappingNames.Address] = mergeAddress(address, houseNr, extension)
  return row as unknown as IShipment
}

const mapWithoutHeaders = (mappings: IMapping[], row: string[]): IShipment => {
  const mappedRow = mappings.reduce((dict, mapping) => {
    dict[mapping.name] = findCellValueRaw(row, mapping.index!)
    return dict
  }, {} as Dictionary)
  return mapRowToShipment(mappedRow)
}

const mapWithHeaders = (
  mappings: IMapping[],
  columns: string[],
  row: Dictionary,
): IShipment => {
  const mappedRow = mappings.reduce((dict, mapping) => {
    dict[mapping.name] = findCellValueWithHeaders(row, columns, mapping.index!)
    return dict
  }, {} as Dictionary)

  return mapRowToShipment(mappedRow)
}

export const withUniqueOrderRef = (result: ParseResult) =>
  result.data.map(
    (shipment) =>
      shipment && {
        ...shipment,
        orderReference: `${
          shipment.orderReference
        } | ${uniqueString().substring(0, 6)}`,
      },
  )

export const mapParsedResultToShipments = (
  result: ParseResult,
  mappings: IMapping[],
  isUniqueOrderRef: boolean,
): IShipment[] => {
  const activeMappings = mappings.filter((mapping) => mapping.index! >= 0)
  const hasHeaders = !!result.meta.fields

  const mapper = hasHeaders
    ? mapWithHeaders.bind(null, activeMappings, result.meta.fields)
    : mapWithoutHeaders.bind(null, activeMappings)

  if (isUniqueOrderRef) {
    return map(withUniqueOrderRef(result), mapper) as unknown as IShipment[]
  }
  return map(result.data, mapper) as unknown as IShipment[]
}

const postShipment = (shipment: IShipment): Promise<AxiosResponse> => {
  return axios.request({
    baseURL: BASE_URL,
    method: 'POST',
    url: 'api/csv-shipments',
    headers: {
      Authorization: `Bearer ${getBearerToken()}`,
    },
    data: {
      orderRef: get(shipment, MappingNames.OrderRef),
      name: get(shipment, MappingNames.Recipient),
      address: get(shipment, MappingNames.Address),
      postal: get(shipment, MappingNames.Postal),
      city: get(shipment, MappingNames.City),
      country: get(shipment, MappingNames.Country) || 'NL',
      email: get(shipment, MappingNames.Email),
      phone: get(shipment, MappingNames.Phone),
      deliveryDate: get(shipment, MappingNames.DeliveryDate),
      deliveryRemarks: get(shipment, MappingNames.Remarks),
    },
  })
}

interface SaveShipmentsParams {
  shipments: IShipment[]
  onProgress: (progress: IShipmentSaveResult) => void
  onCompleted: (failed: IShipment[]) => void
}

export async function saveShipmentsAsync({
  shipments,
  onProgress,
  onCompleted = () => null,
}: SaveShipmentsParams): Promise<void> {
  let saved = 0
  const failed: IShipment[] = []

  onProgress({ saved, total: shipments.length })

  const shipmentSaveHandler = async (shipment: IShipment): Promise<void> => {
    try {
      await postShipment(shipment)
    } catch (error) {
      Sentry.captureException(error)
      failed.push(shipment)
    } finally {
      saved += 1

      if (saved === shipments.length) {
        onCompleted(failed)
      }
      onProgress({ saved, total: shipments.length })
    }
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const shipment of shipments) {
    // eslint-disable-next-line no-await-in-loop
    await shipmentSaveHandler(shipment)
  }
}

export const downloadFailedShipmentsCsv = (shipments: IShipment[]): void => {
  const csv = unparse(shipments, { header: true })
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })

  FileSaver.saveAs(
    blob,
    `trunkrs-failed-shipments_${moment().format('DD-MM-YYYY')}.csv`,
  )
}
