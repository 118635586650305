import { parse, ParseConfig, ParseResult } from 'papaparse'

const defaultParserConfig: ParseConfig = {
  worker: true,
  skipEmptyLines: 'greedy',
}

// eslint-disable-next-line import/prefer-default-export
export function parseCSVFile(
  file: File,
  withHeaders: boolean,
  maxRows?: number,
): Promise<ParseResult> {
  return new Promise<ParseResult>((resolve, reject) => {
    parse(file, {
      ...defaultParserConfig,
      complete: resolve,
      error: reject,
      header: withHeaders,
      preview: maxRows,
    })
  })
}
