import React from 'react'
import ReactDOM from 'react-dom'

export type MountPredicate = () => HTMLElement
export type Mountable<TProps = unknown> =
  | React.ComponentClass<TProps>
  | React.FC<TProps>
  | string

export type Provider = Mountable<{ children: React.ReactNode }>

const runPredicate = (
  predicateOrId: MountPredicate | string,
): HTMLElement | null => {
  if (typeof predicateOrId === 'string') {
    return document.getElementById(predicateOrId as string)
  }

  return (predicateOrId as MountPredicate)()
}

class MiniAppRegistry {
  private providers: Provider[] = []

  private mountables = new Map<MountPredicate | string, Mountable>()

  private mountWithProviders(app: Mountable): JSX.Element {
    return this.providers.reduce(
      (element, Provider) => React.createElement(Provider, null, element),
      React.createElement(app),
    )
  }

  public addApplication(identifier: string, component: Mountable): void

  public addApplication(predicate: MountPredicate, component: Mountable): void

  public addApplication(
    predicateOrId: MountPredicate | string,
    component: Mountable,
  ): void {
    this.mountables.set(predicateOrId, component)
  }

  public addProvider(provider: Provider): this {
    this.providers.unshift(provider)
    return this
  }

  public mountApps = () => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [predicate, Mountable] of this.mountables) {
      const element = runPredicate(predicate)
      if (element) {
        ReactDOM.render(this.mountWithProviders(Mountable), element)
      }
    }
  }
}

export default new MiniAppRegistry()
