import { Shipment } from './typings'
import * as actions from './actions'

export interface State {
  shipments: Shipment[]
  filteredShipments: Shipment[]
  shipmentsAfterDateFilter: Shipment[]
  shipmentsAfterDateTimeFilter: Shipment[]
  selectedShipments: string[]
  fetchingShipments: boolean
  printingLabels: boolean
  cancellingShipments: boolean
  searchTerm?: string
  dateFilterText?: string
  dateTimeFilterText?: string
  apiVersion: number
}

export const initialState: State = {
  shipments: [],
  filteredShipments: [],
  shipmentsAfterDateFilter: [],
  shipmentsAfterDateTimeFilter: [],
  selectedShipments: [],
  fetchingShipments: false,
  printingLabels: false,
  cancellingShipments: false,
  apiVersion: 1,
}

export const reducer = (
  state: State,
  action: actions.ShipmentsListActions,
): State => {
  switch (action.type) {
    case actions.fetchShipments.type: {
      return {
        ...state,
        fetchingShipments: true,
      }
    }

    case actions.fetchShipmentsSuccess.type: {
      return {
        ...state,
        fetchingShipments: false,
        shipments: (action as any).shipments,
        apiVersion: (action as any).apiVersion,
      }
    }

    case actions.searchShipments.type: {
      return {
        ...state,
        selectedShipments: [],
        searchTerm: (action as any).searchTerm,
      }
    }

    case actions.filterByDate.type: {
      return {
        ...state,
        selectedShipments: [],
        dateFilterText: (action as any).dateFilterText,
      }
    }

    case actions.filterByDateTime.type: {
      return {
        ...state,
        selectedShipments: [],
        dateTimeFilterText: (action as any).dateTimeFilterText,
      }
    }

    case actions.printLabels.type: {
      return {
        ...state,
        printingLabels: true,
      }
    }

    case actions.printLabelsSuccess.type: {
      return {
        ...state,
        printingLabels: false,
        selectedShipments: [],
      }
    }

    case actions.cancelShipments.type: {
      return {
        ...state,
        cancellingShipments: true,
      }
    }

    case actions.cancelShipmentsSuccess.type: {
      return {
        ...state,
        selectedShipments: [],
        cancellingShipments: false,
      }
    }

    case actions.cancelShipmentsFail.type: {
      return {
        ...state,
        cancellingShipments: false,
      }
    }

    case actions.selectShipments.type: {
      const { trunkrsNrs } = action as any

      return { ...state, selectedShipments: trunkrsNrs }
    }

    default:
      return state
  }
}
