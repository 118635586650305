/* eslint-disable no-param-reassign,@typescript-eslint/no-explicit-any */
import clsx from 'clsx'
import * as React from 'react'

import ButtonBase from '@material-ui/core/ButtonBase'

import styles from './SmallMenuItem.module.scss'

export interface ISmallMenuItemProps {
  className?: string
  style?: any
  hoverStyle?: any
  value: any
  children: React.ReactNode
}

export interface IInternalProps extends ISmallMenuItemProps {
  isSelected: boolean
  onClick: () => void
}

export const SmallMenuItem: React.FunctionComponent<IInternalProps> = ({
  className,
  style,
  hoverStyle,
  onClick,
  children,
}) => {
  const [hovered, setHovered] = React.useState(false)

  return (
    <ButtonBase
      style={hovered ? { ...style, ...hoverStyle } : style}
      className={clsx('dropdown-item', styles.menuItem, className)}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {children}
    </ButtonBase>
  )
}

export default SmallMenuItem as React.FunctionComponent<ISmallMenuItemProps>
