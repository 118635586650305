import Axios from 'axios'
import get from 'lodash/get'

import { getBearerToken } from '../../../../utils/laravel/token'
import { retrieveItem } from '../../../../utils/storage'
import { MappingNames } from '../Mappings'

import { Configuration } from './typings'

export const CONFIG_STORAGE_KEY = 'csv-mapping-indices'

const mappingNameKeys = Object.keys(MappingNames)

export const applyFieldChange = (
  config: Configuration | null,
  fieldName: keyof MappingNames,
  index: number | undefined,
): Configuration => {
  const newConfig = { ...config! }

  if (!index && index !== 0) {
    delete newConfig[fieldName]
    return newConfig
  }

  mappingNameKeys.forEach((key) => {
    if (newConfig[key as any] === index) {
      delete newConfig[key as any]
    }
  })

  return Object.assign(newConfig, { [fieldName]: index })
}

const loadServerConfig = async (): Promise<Configuration | null> => {
  try {
    const response = await Axios.request<Configuration>({
      method: 'GET',
      url: 'api/csvfield-mapping',
      headers: {
        Authorization: `Bearer ${getBearerToken()}`,
      },
    })

    return response.data as Configuration
  } catch (axiosError) {
    if (get(axiosError, 'response.status') === 404) {
      return null
    }

    throw axiosError
  }
}

export const saveUserConfig = async (config: Configuration): Promise<void> => {
  await Axios.request<Configuration>({
    method: 'PUT',
    url: '/csvfield-settings',
    headers: {
      Authorization: `Bearer ${getBearerToken()}`,
    },
    data: config,
  })
}

export const loadUserConfig = async (): Promise<Configuration | null> => {
  const config = await retrieveItem<Configuration>(CONFIG_STORAGE_KEY)
  if (config) {
    return config
  }

  return loadServerConfig()
}
