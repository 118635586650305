import * as React from 'react'
import clsx from 'clsx'

import ScrollContainer from 'react-perfect-scrollbar'
import ButtonBase from '@material-ui/core/ButtonBase'

import Pages from '../../enum'
import { FileContext } from '../../Context/Files'
import { MappingContext } from '../../Context/Mappings'

import PageHeader from '../../../../components/PageHeader'
import LinkTable from './components/LinkTable'
import MappingCard from './components/MappingCard'
import { isValid, mapToCommonResult } from './helpers'

import UniqueCheck from './components/UniqueCheck'
import styles from './Link.module.scss'

export interface ILinkPageProps {
  onChangePage: (page: Pages) => void
}

const LinkPage: React.FunctionComponent<ILinkPageProps> = ({
  onChangePage,
}) => {
  const { result, reset } = React.useContext(FileContext)
  const { mappings, changeMapping } = React.useContext(MappingContext)

  const [columns, rows] = React.useMemo(
    () => mapToCommonResult(result!),
    [result],
  )
  const isMappingsValid = React.useMemo(() => isValid(mappings), [mappings])

  const handleContinue = React.useCallback(
    () => onChangePage(Pages.preview),
    [onChangePage],
  )
  const handleGoBack = React.useCallback(() => {
    reset()
    onChangePage(Pages.upload)
  }, [onChangePage, reset])

  React.useEffect(() => {
    $('[data-toggle="tooltip"]').tooltip()
  }, [mappings])

  return (
    <>
      <PageHeader
        title="Link your CSV"
        subtitle="Link shipment fields to columns in your CSV."
      >
        <div className={styles.buttonContainer}>
          <ButtonBase className="btn btn-primary" onClick={handleGoBack}>
            Back
          </ButtonBase>
          <ButtonBase
            disabled={!isMappingsValid}
            className={clsx('btn btn-primary', styles.continueButton)}
            onClick={handleContinue}
          >
            Continue
          </ButtonBase>
        </div>
      </PageHeader>

      <UniqueCheck />
      <div className="row">
        {mappings.map((mapping) => (
          <div key={mapping.name} className="col-md-3">
            <MappingCard
              mapping={mapping}
              columns={columns}
              onChangeMapping={changeMapping}
            />
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-md-12">
          <h5 className={clsx('text-secondary', styles.previewTitle)}>
            Data Preview
          </h5>
        </div>
      </div>
      <div className={clsx('row', styles.scrollable)}>
        <ScrollContainer
          options={{ suppressScrollY: true }}
          className="col-md-12"
        >
          <LinkTable
            columns={columns}
            rows={rows}
            mappings={mappings}
            onChangeMapping={changeMapping}
          />
        </ScrollContainer>
      </div>
    </>
  )
}

export default LinkPage
