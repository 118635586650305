import React from 'react'
import clsx from 'clsx'

import { AutoSizer } from 'react-virtualized'
import ButtonBase from '@material-ui/core/ButtonBase'

import { ShipmentsContext } from '../../Context/Shipments'

import PageHeader from '../../../../components/PageHeader'
import VirtualShipmentTable from '../../../../components/VirtualShipmentTable'

import classes from './Failed.module.scss'

const FailedPage: React.FC = () => {
  const { failed, downloadFailed } = React.useContext(ShipmentsContext)

  const handleContinue = React.useCallback(() => {
    window.location.href = '/trunkrs-shipments'
  }, [])

  return (
    <>
      <PageHeader
        title="Some shipments failed"
        subtitle="Please check the details and try again."
      >
        <div className={classes.buttonContainer}>
          <ButtonBase
            className="btn btn-primary"
            type="button"
            onClick={downloadFailed}
          >
            <i className={clsx('material-icons', classes.btnIcon)}>
              cloud_download
            </i>
            Download CSV
          </ButtonBase>
          <ButtonBase
            className={clsx('btn btn-primary', classes.continueButton)}
            type="button"
            onClick={handleContinue}
          >
            To overview
          </ButtonBase>
        </div>
      </PageHeader>

      <AutoSizer>
        {({ width, height }) => (
          <div style={{ width, height: height - 132 }}>
            <VirtualShipmentTable height={height - 132} shipments={failed} />
          </div>
        )}
      </AutoSizer>
    </>
  )
}

export default FailedPage
