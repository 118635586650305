import storage from 'localforage'

storage.config({
  name: 'Trunkrs Shipping Portal',
  version: 1.0,
  storeName: 'Page_Apps',
})

export const storeItem = <TItem>(key: string, item: TItem): Promise<TItem> => {
  return storage.setItem(key, item)
}

export const retrieveItem = <TItem>(key: string): Promise<TItem | null> => {
  return storage.getItem(key)
}
