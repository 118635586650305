/* eslint-disable no-param-reassign */
import { ParseResult } from 'papaparse'
import { IMapping } from '../../Context/Mappings/typings'

export type CommonResult = [string[], { [key: string]: any }[]]

export const mapToCommonResult = (result: ParseResult): CommonResult => {
  const previewSet = result.data.slice(0, 8)

  if (result.meta.fields) {
    return [result.meta.fields, previewSet]
  }

  const [firstRow] = result.data

  const fields = firstRow.map((_: string, idx: number) => `Column ${idx + 1}`)
  // eslint-disable-next-line no-shadow
  const data = previewSet.map((data: string[]) =>
    data.reduce((rowValue, fieldValue, idx) => {
      const fieldName = fields[idx]
      rowValue[fieldName] = fieldValue

      return rowValue
    }, {} as { [key: string]: any }),
  )

  return [fields, data]
}

export const isValid = (mappings: IMapping[]): boolean =>
  mappings.every((mapping) => !mapping.required || mapping.index! >= 0)
