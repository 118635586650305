/* eslint-disable @typescript-eslint/ban-types */
interface Actions {
  readonly type: string
}

interface Props<T> {
  _as: 'props'
  _p: T
}

export function props<P extends object>(): Props<P> {
  return { _as: 'props', _p: undefined! }
}

export type ActionCreator = (() => Actions) & Actions

export type ActionCreatorWProps<TProps extends object> = ((
  props: TProps,
) => TProps & Actions) &
  Actions

export function createAction(type: string): ActionCreator
export function createAction<TProps extends object>(
  type: string,
  propConfig: Props<TProps>,
): ActionCreatorWProps<TProps>
export function createAction<TProps extends object>(
  type: string,
  propConfig?: { _as: 'props' },
): ActionCreator {
  const creator = propConfig
    ? // eslint-disable-next-line no-shadow
      (props: object) => ({ ...props, type })
    : () => ({ type })

  return Object.defineProperty(creator, 'type', {
    value: type,
    writable: false,
  })
}

export function union<
  TCreator extends { [key: string]: ActionCreator | ActionCreatorWProps<any> },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(creators: TCreator): ReturnType<TCreator[keyof TCreator]> {
  return undefined!
}
