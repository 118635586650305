import clsx from 'clsx'
import * as React from 'react'
import throttle from 'lodash/throttle'

import { MappingNames } from '../../../apps/ImportCSV/Context/Mappings'

import styles from './HeaderRow.module.scss'

export interface IHeaderRowProps {
  columns: string[]
}

class HeaderRow extends React.PureComponent<IHeaderRowProps> {
  private rowRef = React.createRef<HTMLDivElement>()

  public setScrollLeft = throttle((scrollLeft: number): void => {
    const { current: row } = this.rowRef
    if (!row) return

    row.style.transform = `translateZ(0) translateX(-${scrollLeft}px)`
  }, 8)

  public render() {
    const { columns } = this.props

    return (
      <div className={styles.container}>
        <div
          ref={this.rowRef}
          className={styles.row}
          style={{ width: (columns.length - 1) * 250 + 400 }}
        >
          {columns.map((columnName) => (
            <div
              key={columnName}
              className={clsx(
                styles.header,
                columnName === MappingNames.Address && styles.address,
              )}
            >
              {columnName}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export default HeaderRow
