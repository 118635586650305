import * as React from 'react'
import * as Sentry from '@sentry/browser'

import { ConfigurationContext, IConfigurationContext } from '.'
import { storeItem } from '../../../../utils/storage'
import { MappingNames } from '../Mappings'
import {
  applyFieldChange,
  CONFIG_STORAGE_KEY,
  loadUserConfig,
  saveUserConfig,
} from './helpers'
import { Configuration } from './typings'

export interface IConfigurationProps {
  children: React.ReactNode
}

interface IInternalState {
  isLoaded: boolean
  isChanged: boolean
}

const ConfigurationProvider = ({ children }: IConfigurationProps) => {
  const [isLoading, setLoading] = React.useState(false)
  const [config, setConfig] = React.useState<Configuration | null>(null)

  const { current: internalState } = React.useRef<IInternalState>({
    isLoaded: false,
    isChanged: false,
  })

  const setField = React.useCallback(
    (field: keyof MappingNames, index: number) => {
      const newConfig: Configuration = applyFieldChange(config, field, index)

      setConfig(newConfig)

      internalState.isChanged = true
    },
    [config, internalState],
  )

  const loadConfig =
    React.useCallback(async (): Promise<Configuration | null> => {
      setLoading(true)

      try {
        const result = await loadUserConfig()
        setConfig(result)

        return result
      } catch (error) {
        Sentry.captureException(error)
      }

      setLoading(false)
      return null
    }, [])

  const saveConfig = React.useCallback(async (): Promise<void> => {
    if (!config || !internalState.isChanged) return

    setLoading(true)

    try {
      await saveUserConfig(config)
    } catch (error) {
      Sentry.captureException(error)
    }

    setLoading(false)
  }, [config, internalState])

  const providerValue = React.useMemo<IConfigurationContext>(
    () => ({
      isLoading,
      config,
      setField,
      loadConfig,
      saveConfig,
    }),
    [isLoading, config, setField, loadConfig, saveConfig],
  )

  React.useEffect(() => {
    storeItem(CONFIG_STORAGE_KEY, config).catch(Sentry.captureException)
  }, [config])

  return (
    <ConfigurationContext.Provider value={providerValue}>
      {children}
    </ConfigurationContext.Provider>
  )
}

export default ConfigurationProvider
