import * as Yup from 'yup'
import i18n from 'i18next'

const t = i18n.getFixedT(i18n.language, 'MANUAL_SHIPMENT')
const postalCodeRegex = /^[0-9]{4}\W?(?:[A-Za-z]{2})?$/

const ManualShipmentSchema = Yup.object().shape({
  orderNumber: Yup.string().required(t('ORDER_REFERENCE_REQUIRED')),
  recipientName: Yup.string().required(t('RECIPIENT_NAME_REQUIRED')),
  emailAdd: Yup.string().required(t('EMAIL_REQUIRED')).email(t('EMAIL_ERROR')),
  phoneNumber: Yup.string()
    .min(10, t('PHONE_MIN_LENGTH'))
    .max(18, t('PHONE_MAX_LENGTH')),
  houseNumber: Yup.string().required(t('HOUSE_NUMBER_REQUIRED')),
  postalCode: Yup.string()
    .matches(postalCodeRegex, t('POSTAL_CODE_ERROR'))
    .required(t('POSTAL_CODE_REQUIRED')),
  streetName: Yup.string().required(t('STREET_NAME_REQUIRED')),
  city: Yup.string().required(t('CITY_REQUIRED')),
  country: Yup.string()
    .required(t('COUNTRY_REQUIRED'))
    .min(2, t('COUNTRY_REQUIRED')),
  delivery: Yup.string().required(t('DELIVERY_OPTION_REQUIRED')),
})

export default ManualShipmentSchema
