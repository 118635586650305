import * as React from 'react'

export interface ITrunkrsLogoProps {
  className?: string
  style?: React.CSSProperties
}

const TrunkrsLogo: React.FunctionComponent<ITrunkrsLogoProps> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 462 303">
    <path
      fill="#00D163"
      d="M14.53,92.17a7.41,7.41,0,0,0,5,12.89l133.6-.37a11.82,11.82,0,0,0,7.9-3.06l99.5-90.44-147.19.41A26.47,26.47,0,0,0,95.6,18.48Z"
    />
    <path
      fill="#FFFFFF"
      d="M448.52,11.24l-160.77.38a13.59,13.59,0,0,0-9.07,3.51l-95.3,86.26a11.28,11.28,0,0,0-3.69,8.35v66.6a7.05,7.05,0,0,1-7,7l-61.48.14A25.62,25.62,0,0,0,94,190.18L12.69,264.11a7.41,7.41,0,0,0,5,12.89L176,276.62a9.66,9.66,0,0,0,6.48-2.5l96.4-87.26a11.29,11.29,0,0,0,3.69-8.35v-66.6a7.05,7.05,0,0,1,7-7l65.94-.16a14,14,0,0,0,9.38-3.64l87.58-79.6A5.87,5.87,0,0,0,448.52,11.24Z"
    />
  </svg>
)

export default TrunkrsLogo
