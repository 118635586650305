import React from 'react'

import { InPageApp } from '../../utils/mounting/inPageApp'
import ShipmentsListProvider from './store/ShipmentsList/Provider'

import PageContainer from '../../components/PageContainer'
import MainContent from './components/MainContent'
import '../../i18n'

import 'react-perfect-scrollbar/dist/css/styles.css'

@InPageApp('trunkrs-shipments')
class ShipmentsList extends React.PureComponent {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  render() {
    return (
      <ShipmentsListProvider>
        <PageContainer>
          <MainContent />
        </PageContainer>
      </ShipmentsListProvider>
    )
  }
}

export default ShipmentsList
