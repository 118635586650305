import * as React from 'react'
import get from 'lodash/get'

import { IMapping } from './typings'

export interface IMappingContext {
  readonly isLoading: boolean
  readonly mappings: IMapping[]
  readonly isUniqueOrderRef: boolean
  changeMapping: (mapping: IMapping) => void
  toggleMakeOrderRefUnique: () => void
  loadMappings: () => void
}

export const cardColors = [
  '#FF5351',
  '#12d2fc',
  '#ffb333',
  '#29a389',
  '#201047',
  '#B21BE0',
  '#FF9551',
  '#516Dff',
  '#FFA69E',
  '#5f0f40',
  '#FF1744',
  '#00D763',
]

export enum MappingNames {
  OrderRef = 'Order Reference',
  Recipient = 'Recipient Name',
  Address = 'Address Line',
  HouseNr = 'House Number',
  NrExtension = 'Extension',
  Postal = 'Postal Code',
  City = 'City',
  Country = 'Country',
  Email = 'Email Address',
  Phone = 'Phone Number',
  DeliveryDate = 'Delivery Date',
  Remarks = 'Delivery Instructions',
}

type ReverseMappingNames = { [key in MappingNames]: keyof MappingNames }

export const reverseMappingNames: ReverseMappingNames = Object.keys(
  MappingNames,
).reduce(
  (reverse, key) => Object.assign(reverse, { [get(MappingNames, key)]: key }),
  {} as any,
)

export const defaultMappings: IMapping[] = [
  {
    name: MappingNames.OrderRef,
    order: 0,
    color: cardColors[0],
    required: true,
  },
  {
    name: MappingNames.Recipient,
    order: 1,
    color: cardColors[7],
    required: true,
  },
  {
    name: MappingNames.Address,
    order: 2,
    color: cardColors[1],
    required: true,
  },
  {
    name: MappingNames.HouseNr,
    order: 3,
    color: cardColors[8],
    required: false,
  },
  {
    name: MappingNames.NrExtension,
    order: 4,
    color: cardColors[9],
    required: false,
  },
  { name: MappingNames.Postal, order: 5, color: cardColors[2], required: true },
  { name: MappingNames.City, order: 6, color: cardColors[3], required: true },
  {
    name: MappingNames.Country,
    order: 7,
    color: cardColors[11],
    required: false,
  },
  { name: MappingNames.Email, order: 8, color: cardColors[4], required: true },
  { name: MappingNames.Phone, order: 9, color: cardColors[5], required: false },
  {
    name: MappingNames.DeliveryDate,
    order: 10,
    color: cardColors[6],
    required: false,
  },
  {
    name: MappingNames.Remarks,
    order: 11,
    color: cardColors[10],
    required: false,
  },
]

export const MappingContext = React.createContext<IMappingContext>({
  isLoading: false,
  mappings: defaultMappings,
  isUniqueOrderRef: true,
  changeMapping: () => null,
  toggleMakeOrderRefUnique: () => null,
  loadMappings: () => {
    throw new Error('Not implemented!')
  },
})
