import * as React from 'react'
import { ParseError, ParseResult } from 'papaparse'

import { FileContext, IFilesContext } from '.'
import { parseCSVFile } from './helpers'

export interface IFileProviderProps {
  children: React.ReactNode
}

const FileProvider: React.FunctionComponent<IFileProviderProps> = ({
  children,
}) => {
  const [isWorking, setIsWorking] = React.useState<boolean>(false)
  const [current, setCurrent] = React.useState<File | null>(null)
  const [result, setResult] = React.useState<ParseResult | null>(null)
  const [error, setError] = React.useState<ParseError | null>(null)

  const contextValue = React.useMemo<IFilesContext>(
    () => ({
      isWorking,
      current,
      result,
      error,
      parseFile: (file, hasHeaders: boolean, maxRows?: number) => {
        setCurrent(file)
        setIsWorking(true)
        setError(null)
        setResult(null)

        return parseCSVFile(file, hasHeaders, maxRows)
          .then((parseResult) => {
            setIsWorking(false)
            setResult(parseResult)

            return parseResult
          })
          .catch((parseError) => {
            setIsWorking(false)
            setError(parseError)
            return null
          })
      },
      reset: () => {
        setIsWorking(false)
        setCurrent(null)
        setResult(null)
        setError(null)
      },
    }),
    [isWorking, current, result, error],
  )

  return (
    <FileContext.Provider value={contextValue}>{children}</FileContext.Provider>
  )
}

export default FileProvider
