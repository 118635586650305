import React from 'react'
import { State } from './reducer'

export interface ShipmentsListState extends State {
  fetchShipmentList(): Promise<void>
  printLabels(): Promise<void>
  printLabelsCombined(): Promise<void>
  cancelShipments(): Promise<void>
  searchShipments(searchText: string): void
  filterByDate(dateFilterText: string): void
  filterByDateTime(dateTimeFilterText: string): void
  toggleSelection(trunkrsNr: string): void
  setSelection(trunkrsNrs?: string[]): void
}

const ShipmentsList = React.createContext<ShipmentsListState>({
  shipments: [],
  filteredShipments: [],
  shipmentsAfterDateFilter: [],
  shipmentsAfterDateTimeFilter: [],
  selectedShipments: [],
  fetchingShipments: false,
  printingLabels: false,
  cancellingShipments: false,
  apiVersion: 1,
  fetchShipmentList: () => Promise.reject(new Error('Not implemented!')),
  printLabels: () => Promise.reject(new Error('Not implemented!')),
  printLabelsCombined: () => Promise.reject(new Error('Not implemented!')),
  cancelShipments: () => Promise.reject(new Error('Not implemented!')),
  searchShipments: () => {
    throw new Error('Not implemented!')
  },
  filterByDate: () => {
    throw new Error('Not implemented!')
  },
  filterByDateTime() {
    throw new Error('Not implemented!')
  },
  toggleSelection: () => {
    throw new Error('Not implemented!')
  },
  setSelection: () => {
    throw new Error('Not implemented!')
  },
})

export default ShipmentsList
