import clsx from 'clsx'
import * as React from 'react'
import { TransitionGroup } from 'react-transition-group'
import * as Sentry from '@sentry/browser'

import Pages from '../../enum'
import Fade from '../../../../components/Fade'
import PageHeader from '../../../../components/PageHeader'
import { FileContext } from '../../Context/Files'
import { MappingContext } from '../../Context/Mappings'

import DropZone from './components/DropZone'
import SelectedFile from './components/SelectedFile'

import styles from './Upload.module.scss'
import DepricationMessage from './components/DepricationMessage'

export interface IUploadPageProps {
  onChangePage: (target: Pages) => void
}

const UploadPage: React.FunctionComponent<IUploadPageProps> = ({
  onChangePage,
}) => {
  const [tempFile, setTempFile] = React.useState<File | null>(null)
  const [hasHeaders, setHasHeaders] = React.useState<boolean>(true)

  const { parseFile, result, isWorking } = React.useContext(FileContext)
  const { loadMappings } = React.useContext(MappingContext)

  const handleDropFile = React.useCallback(
    ([file]: File[], rejected: File[]) => {
      if (file) setTempFile(file)

      if (rejected) {
        rejected.forEach((rejectedFile) => {
          Sentry.captureEvent({
            event_id: 'file_rejected',
            message: 'Rejected File uploaded',
            extra: {
              fileType: rejectedFile.type,
            },
          })
        })
      }
    },
    [],
  )

  const handleOnStartParsing = React.useCallback(() => {
    parseFile(tempFile as File, hasHeaders, 8).catch(Sentry.captureException)
  }, [hasHeaders, tempFile, parseFile])

  React.useEffect(
    () => loadMappings(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  if (result) {
    onChangePage(Pages.link)
  }

  return (
    <>
      <DepricationMessage googleForm="https://docs.google.com/forms/d/e/1FAIpQLSeca6QhwzHDs0ynAh1aZY7QkT-fT6VD5sGbqtTo8NBMQWLGhw/viewform" />
      <PageHeader
        title="Import your CSV"
        subtitle="Import your Trunkrs shipments, your way."
      />
      <div className="row">
        <div className={clsx('col-md-12', styles.container)}>
          <TransitionGroup component={null}>
            {!tempFile && (
              <Fade key="file-uploader" className={styles.transitionBoundary}>
                <DropZone onDropFile={handleDropFile} />
              </Fade>
            )}
            {tempFile && (
              <Fade
                timeout={200}
                key="selected-file"
                className={styles.transitionBoundary}
              >
                <SelectedFile
                  isWorking={isWorking}
                  file={tempFile}
                  hasHeaders={hasHeaders}
                  onToggleHeaders={setHasHeaders}
                  onStartParsing={handleOnStartParsing}
                />
              </Fade>
            )}
          </TransitionGroup>
        </div>
      </div>
    </>
  )
}

export default UploadPage
