// eslint-disable-next-line import/no-unresolved
import { ComponentsProps } from '@material-ui/core/styles/props'

import buttonBase from './buttonBase'

const props: ComponentsProps = {
  MuiButtonBase: buttonBase,
}

export default props
