/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import moment from 'moment'
import * as Sentry from '@sentry/browser'

import FileSaver from 'file-saver'
import Fuse from 'fuse.js'

import laravelClient from '../../../../utils/laravel/client'

import Notifications from '../../../../contexts/Notifications'
import ShipmentsList from '.'
import { reducer, initialState } from './reducer'
import * as actions from './actions'
import { ShipmentsAndApiVersion } from './typings'

interface Props {
  children: React.ReactNode
}

const ShipmentsListProvider: React.FC<Props> = ({ children }: Props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const { addNotification } = React.useContext(Notifications)

  const fetchShipmentList = React.useCallback(async () => {
    dispatch(actions.fetchShipments())
    try {
      const response = await laravelClient.get<ShipmentsAndApiVersion>(
        'api/shipments-list',
      )
      dispatch(
        actions.fetchShipmentsSuccess({
          shipments: orderBy(response.data.shipments, ['created_at'], ['desc']),
          apiVersion: response.data.apiVersion,
        }),
      )
    } catch (axiosError) {
      dispatch(actions.fetchShipmentsFail({ error: axiosError }))
      if (get(axiosError, 'response.status') === 401) {
        window.location.replace('/client/login')
      }
      Sentry.captureException(axiosError)
      throw axiosError
    }
  }, [])

  const setSelection = React.useCallback(
    (trunkrsNrs?: string[]) =>
      dispatch(
        actions.selectShipments({
          trunkrsNrs: trunkrsNrs || [],
        }),
      ),
    [],
  )

  const toggleSelection = React.useCallback(
    (trunkrsNr: string) => {
      const { selectedShipments } = state

      if (selectedShipments.includes(trunkrsNr)) {
        setSelection(selectedShipments.filter((nr) => nr !== trunkrsNr))
      } else {
        setSelection([...selectedShipments, trunkrsNr])
      }
    },
    [state, setSelection],
  )

  const searchShipments = React.useCallback(
    (searchTerm: string) => dispatch(actions.searchShipments({ searchTerm })),
    [],
  )

  const filterByDate = React.useCallback(
    (dateFilterText: string) =>
      dispatch(actions.filterByDate({ dateFilterText })),
    [],
  )

  const filterByDateTime = React.useCallback(
    (dateTimeFilterText: string) =>
      dispatch(actions.filterByDateTime({ dateTimeFilterText })),
    [],
  )

  const printLabels = React.useCallback(async () => {
    dispatch(actions.printLabels())
    try {
      const response = await laravelClient.post<any>(
        'api/print-labels',
        { trunkrsNrs: orderBy(state.selectedShipments, ['desc']) },
        { responseType: 'blob' },
      )
      FileSaver.saveAs(
        response.data,
        `shipments_label_${moment().format('DD-MM-YYYY_HH:mm')}.pdf`,
      )
      dispatch(actions.printLabelsSuccess())
    } catch (axiosError) {
      dispatch(actions.printLabelsFail({ error: axiosError }))
      Sentry.captureException(axiosError)
      throw axiosError
    }
  }, [state.selectedShipments])

  const printLabelsCombined = React.useCallback(async () => {
    dispatch(actions.printLabels())
    try {
      const response = await laravelClient.post<any>(
        'api/print-labels-combined',
        { trunkrsNrs: orderBy(state.selectedShipments, ['desc']) },
        { responseType: 'blob' },
      )
      FileSaver.saveAs(
        response.data,
        `shipments_label_${moment().format('DD-MM-YYYY_HH:mm')}.pdf`,
      )
      dispatch(actions.printLabelsSuccess())
    } catch (axiosError) {
      dispatch(actions.printLabelsFail({ error: axiosError }))
      Sentry.captureException(axiosError)
      throw axiosError
    }
  }, [state.selectedShipments])

  const cancelShipments = React.useCallback(async () => {
    dispatch(actions.cancelShipments())
    try {
      const response = await laravelClient.post(
        'api/cancel-shipments',
        state.selectedShipments,
      )
      await fetchShipmentList()

      dispatch(actions.cancelShipmentsSuccess())
      addNotification(response.data.message, 'success')
    } catch (axiosError) {
      dispatch(actions.cancelShipmentsFail({ error: axiosError }))
      addNotification(get(axiosError, 'response.data.error'), 'error')
      Sentry.captureException(axiosError)
      throw axiosError
    }
  }, [addNotification, fetchShipmentList, state.selectedShipments])

  const searcher = React.useMemo(
    () =>
      new Fuse(state.shipments, {
        keys: [
          'trunkrsNumber',
          'orderReference',
          'customerName',
          'customerAddress',
          'customerPostCode',
        ],
        threshold: 0.3,
      }),
    [state.shipments],
  )

  const searcherAfterDateFilter = React.useMemo(
    () =>
      new Fuse(state.shipmentsAfterDateFilter, {
        keys: [
          'trunkrsNumber',
          'orderReference',
          'customerName',
          'customerAddress',
          'customerPostCode',
        ],
        threshold: 0.3,
      }),
    [state.shipmentsAfterDateFilter],
  )

  const searcherAfterDateTimeFilter = React.useMemo(
    () =>
      new Fuse(state.shipmentsAfterDateTimeFilter, {
        keys: [
          'trunkrsNumber',
          'orderReference',
          'customerName',
          'customerAddress',
          'customerPostCode',
        ],
        threshold: 0.3,
      }),
    [state.shipmentsAfterDateTimeFilter],
  )

  const dateFilter = React.useMemo(
    () =>
      new Fuse(state.shipments, {
        keys: ['trunkrsDeliveryTime'],
        threshold: 0.0,
      }),
    [state.shipments],
  )

  const dateTimeFilter = React.useMemo(
    () =>
      new Fuse(state.shipments, {
        keys: ['created_at'],
        threshold: 0.0,
        useExtendedSearch: true,
      }),
    [state.shipments],
  )

  const filteredShipments = React.useMemo(() => {
    if (
      !state.searchTerm &&
      !state.dateFilterText &&
      !state.dateTimeFilterText
    ) {
      return state.shipments
    }

    if (state.searchTerm && state.dateFilterText) {
      return searcherAfterDateFilter
        .search(state.searchTerm)
        .map((result) => result.item)
    }

    if (state.searchTerm && state.dateTimeFilterText) {
      return searcherAfterDateTimeFilter
        .search(state.searchTerm)
        .map((result) => result.item)
    }

    if (state.dateFilterText) {
      state.shipmentsAfterDateFilter = dateFilter
        .search(state.dateFilterText)
        .map((result) => result.item)
      return state.shipmentsAfterDateFilter
    }

    if (state.dateTimeFilterText) {
      state.shipmentsAfterDateTimeFilter = dateTimeFilter
        .search(state.dateTimeFilterText)
        .map((result) => result.item)
      return state.shipmentsAfterDateTimeFilter
    }

    return !state.searchTerm
      ? state.shipments
      : searcher.search(state.searchTerm).map((result) => result.item)
  }, [
    state.searchTerm,
    state.dateFilterText,
    state.dateTimeFilterText,
    state.shipments,
    state.shipmentsAfterDateFilter,
    state.shipmentsAfterDateTimeFilter,
    searcher,
    searcherAfterDateFilter,
    searcherAfterDateTimeFilter,
    dateFilter,
    dateTimeFilter,
  ])

  const contextValue = React.useMemo(
    () => ({
      ...state,
      fetchShipmentList,
      searchShipments,
      filterByDate,
      filterByDateTime,
      cancelShipments,
      printLabels,
      printLabelsCombined,
      filteredShipments,
      toggleSelection,
      setSelection,
    }),
    [
      state,
      fetchShipmentList,
      searchShipments,
      filterByDate,
      filterByDateTime,
      cancelShipments,
      printLabels,
      printLabelsCombined,
      filteredShipments,
      toggleSelection,
      setSelection,
    ],
  )

  return (
    <ShipmentsList.Provider value={contextValue}>
      {children}
    </ShipmentsList.Provider>
  )
}
export default ShipmentsListProvider
