import React from 'react'

import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormLabel from '@material-ui/core/FormLabel'

import InputBase, { InputBaseProps } from '@material-ui/core/InputBase'
import { useField } from 'formik'

import clsx from 'clsx'
import classes from './FormikInput.module.scss'

interface FormikInputProps
  extends Omit<Omit<InputBaseProps, 'value'>, 'onChange'> {
  label: string
  name: string
  optional?: boolean
}

const FormikInput: React.FC<FormikInputProps> = ({
  className,
  name,
  label,
  optional,
  ...inputProps
}) => {
  const [t] = useTranslation('MANUAL_SHIPMENT')
  const [field, meta] = useField(name)
  const hasError = !!(meta.touched && meta.error)

  return (
    <FormControl error={hasError} fullWidth>
      <FormLabel htmlFor={name}>
        {label}
        {optional && <span className={classes.subtext}> {t('OPTIONAL')}</span>}
      </FormLabel>
      <InputBase
        {...field}
        {...inputProps}
        id={name}
        className={clsx('form-control', className)}
      />
      <Fade in={hasError}>
        <Box>
          <FormHelperText error className={classes.error_text}>
            {meta.error || ' '}
          </FormHelperText>
        </Box>
      </Fade>
    </FormControl>
  )
}

export default FormikInput
