/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import Color from 'color'
import clsx from 'clsx'
import groupBy from 'lodash/groupBy'

import { IMapping } from '../../../../Context/Mappings/typings'

import LinkTableHeader from './Header'

import styles from './LinkTable.module.scss'

export interface ILinkTableProps {
  mappings: IMapping[]
  columns: string[]
  rows: { [key: string]: any }[]
  onChangeMapping: (mapping: IMapping) => void
}

const LinkTable: React.FunctionComponent<ILinkTableProps> = ({
  columns,
  rows,
  mappings,
  onChangeMapping,
}) => {
  const pivotedMappings = React.useMemo(
    () =>
      groupBy(
        mappings.filter((m) => (m.index as number) >= 0),
        'index',
      ),
    [mappings],
  )

  return (
    <table className={clsx('table table-borderless', styles.table)}>
      <thead className="text-primary">
        <tr>
          {React.useMemo(
            () =>
              columns.map((columnName, columnIdx) => (
                <LinkTableHeader
                  key={columnName}
                  columnIndex={columnIdx}
                  name={columnName}
                  mappings={mappings}
                  onChangeMapping={onChangeMapping}
                />
              )),
            [columns, mappings, onChangeMapping],
          )}
        </tr>
      </thead>
      <tbody>
        {React.useMemo(
          () =>
            rows.map((row, idx) => (
              <tr key={idx}>
                {columns.map((column, columnIndex) => {
                  const color =
                    pivotedMappings[columnIndex] &&
                    Color(pivotedMappings[columnIndex][0].color).lighten(0.45)

                  return (
                    <td
                      key={`${column}-${row[column]}`}
                      className={styles.cell}
                      style={{
                        backgroundColor: color && (color as any),
                        color: color && color.isDark() ? '#FFF' : '#333',
                      }}
                    >
                      {row[column]}
                    </td>
                  )
                })}
              </tr>
            )),
          [columns, rows, pivotedMappings],
        )}
      </tbody>
    </table>
  )
}

export default LinkTable
