// eslint-disable-next-line import/no-unresolved
import { Overrides } from '@material-ui/core/styles/overrides'

import shape from '../shape'

const overrides: Overrides = {
  MuiTextField: {
    root: {
      borderRadius: shape.borderRadius,
    },
  },
  MuiButtonBase: {
    root: {
      margin: undefined,
      padding: undefined,
      borderRadius: undefined,
    },
  },
}
export default overrides
