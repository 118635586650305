import * as actions from './actions'
import { Timeslot } from './typings'

export interface State {
  creatingShipment: boolean
  successShipment: boolean
  isFetchingTimeslot: boolean
  timeslot: Timeslot[]
}

export const initialState: State = {
  creatingShipment: false,
  successShipment: false,
  isFetchingTimeslot: false,
  timeslot: [],
}

export const reducer = (
  state: State,
  action: actions.ManualShipmentActions,
): State => {
  switch (action.type) {
    case actions.createShipment.type: {
      return {
        ...state,
        creatingShipment: true,
      }
    }

    case actions.createShipmentSuccess.type: {
      return {
        ...state,
        creatingShipment: false,
        successShipment: true,
      }
    }

    case actions.createShipmentFail.type: {
      return {
        ...state,
        creatingShipment: false,
        successShipment: false,
      }
    }
    case actions.fetchDeliveryDate.type: {
      return {
        ...state,
        isFetchingTimeslot: true,
      }
    }
    case actions.fetchDeliveryDateSuccess.type: {
      return {
        ...state,
        isFetchingTimeslot: false,
        timeslot: (action as any).timeslot,
      }
    }
    case actions.fetchDeliveryDateFail.type: {
      return {
        ...state,
        isFetchingTimeslot: false,
      }
    }
    default:
      return state
  }
}
