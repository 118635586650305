/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from 'react'
import * as Sentry from '@sentry/browser'

import { ConfigurationContext } from '../Configuration'
import { FileContext } from '../Files'
import { MappingContext } from '../Mappings'

import { IShipmentSaveResult, IShipmentsContext, ShipmentsContext } from '.'
import {
  downloadFailedShipmentsCsv,
  mapParsedResultToShipments,
  saveShipmentsAsync,
} from './helpers'
import { IShipment } from './typings'

export interface IShipmentsProviderProps {
  children: React.ReactNode
}

const ShipmentsProvider: React.FunctionComponent<IShipmentsProviderProps> = ({
  children,
}) => {
  const { result, parseFile, current } = React.useContext(FileContext)
  const { mappings, isUniqueOrderRef } = React.useContext(MappingContext)
  const { saveConfig } = React.useContext(ConfigurationContext)

  const [isCompleted, setCompleted] = React.useState(false)
  const [failed, setFailed] = React.useState<IShipment[]>([])
  const [shipments, setShipments] = React.useState<IShipment[] | null>(null)

  const mapShipments = React.useCallback(async () => {
    const parsed = await parseFile(current!, !!result!.meta.fields)

    setShipments(
      mapParsedResultToShipments(parsed!, mappings, isUniqueOrderRef),
    )
  }, [parseFile, current, result, mappings, isUniqueOrderRef])

  const saveShipments = React.useCallback(
    (onProgress: (progress: IShipmentSaveResult) => void) => {
      saveConfig().catch(Sentry.captureException)

      return saveShipmentsAsync({
        shipments: [...shipments!].reverse(),
        onProgress,
        onCompleted: (failedShipments) => {
          setFailed(failedShipments)
          setCompleted(true)
        },
      })
    },
    [shipments, saveConfig],
  )

  const downloadFailed = React.useCallback(() => {
    downloadFailedShipmentsCsv(failed)
  }, [failed])

  const providerValue = React.useMemo<IShipmentsContext>(
    () => ({
      shipments,
      failed,
      isCompleted,
      mapShipments,
      saveShipments,
      downloadFailed,
    }),
    [
      shipments,
      failed,
      isCompleted,
      mapShipments,
      saveShipments,
      downloadFailed,
    ],
  )

  return (
    <ShipmentsContext.Provider value={providerValue}>
      {children}
    </ShipmentsContext.Provider>
  )
}

export default ShipmentsProvider
