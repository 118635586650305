import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import ButtonBase from '@material-ui/core/ButtonBase'

import ShipmentStates from '../../constants/ShipmentStates'

import classes from './StateSpan.module.scss'

interface StateSpanProps {
  shipmentState: string
  onClick: () => void
}

const StateSpan: React.FC<StateSpanProps> = ({ shipmentState, onClick }) => {
  const [t] = useTranslation('SHIPMENT_STATES')
  return (
    <ButtonBase disableRipple onClick={onClick}>
      <span
        className={clsx(
          classes.shipment_state,
          shipmentState === ShipmentStates.ShipmentReceived && classes.expected,
          shipmentState === ShipmentStates.ScannedAtCentralHub &&
            classes.at_central_hub,
          shipmentState === ShipmentStates.AssignedToTour &&
            classes.at_central_hub,
          shipmentState === ShipmentStates.ScannedAtSubDepot &&
            classes.at_regional_hub,
          shipmentState === ShipmentStates.NotAcceptedByDriver &&
            classes.at_regional_hub,
          shipmentState === ShipmentStates.AcceptedByDriver &&
            classes.on_its_way,
          shipmentState === ShipmentStates.DriverNearRecipient &&
            classes.on_its_way,
          shipmentState === ShipmentStates.Delivered && classes.delivered,
          shipmentState === ShipmentStates.DeliveredToNeighbour &&
            classes.delivered_to_neighbour,
          shipmentState === ShipmentStates.NotDelivered &&
            classes.not_delivered,
          shipmentState === ShipmentStates.NotArrived && classes.not_delivered,
          shipmentState === ShipmentStates.Cancelled && classes.cancelled,
          shipmentState === ShipmentStates.Investigate && classes.expected,
          shipmentState === ShipmentStates.ReturnToSender && classes.expected,
          shipmentState === ShipmentStates.DataProcessed && classes.expected,
          shipmentState === ShipmentStates.ShipmentSorted &&
            classes.at_central_hub,
          shipmentState === ShipmentStates.ShipmentSortedAtSubDepot &&
            classes.at_regional_hub,
          shipmentState === ShipmentStates.DeclinedByDriver &&
            classes.at_regional_hub,
          shipmentState === ShipmentStates.AcceptedDriver && classes.on_its_way,
          shipmentState === ShipmentStates.ShipmentDelivered &&
            classes.delivered,
          shipmentState === ShipmentStates.ShipmentToNeighbour &&
            classes.delivered_to_neighbour,
          shipmentState === ShipmentStates.ShipmentNotDelivered &&
            classes.not_delivered,
          shipmentState === ShipmentStates.CancelledBySender &&
            classes.cancelled,
          shipmentState === ShipmentStates.CancelledByTrunkrs &&
            classes.cancelled,
        )}
      >
        {t(shipmentState)}
      </span>
    </ButtonBase>
  )
}

export default StateSpan
