import { createAction, union, props } from '../../../../utils/actions'
import { Shipment } from './typings'

interface FailureResult {
  error: Error
}

export const fetchShipments = createAction('FETCHING_SHIPMENTS')
export const fetchShipmentsSuccess = createAction(
  'FETCHING_SHIPMENTS_SUCCESS',
  props<{ shipments: Shipment[]; apiVersion: number }>(),
)
export const fetchShipmentsFail = createAction(
  'FETCHING_SHIPMENTS_FAIL',
  props<FailureResult>(),
)

export const printLabels = createAction('PRINTING_LABELS')
export const printLabelsSuccess = createAction('PRINTING_LABELS_SUCCESS')
export const printLabelsFail = createAction(
  'PRINTING_LABELS_FAIL',
  props<FailureResult>(),
)

export const cancelShipments = createAction('CANCELLING_SHIPMENTS')
export const cancelShipmentsSuccess = createAction(
  'CANCELLING_SHIPMENTS_SUCCESS',
)
export const cancelShipmentsFail = createAction(
  'CANCELLING_SHIPMENTS_FAIL',
  props<FailureResult>(),
)

export const searchShipments = createAction(
  'SEARCHING_SHIPMENTS',
  props<{ searchTerm: string }>(),
)

export const selectShipments = createAction(
  'SELECT_SHIPMENTS',
  props<{ trunkrsNrs: string[] }>(),
)

export const filterByDate = createAction(
  'FILTERING_BY_DATE',
  props<{ dateFilterText: string }>(),
)

export const filterByDateTime = createAction(
  'FILTER_BY_TIME',
  props<{ dateTimeFilterText: string }>(),
)

const all = union({
  fetchShipments,
  fetchShipmentsSuccess,
  fetchShipmentsFail,
  filterByDate,
  filterByDateTime,
  printLabels,
  printLabelsSuccess,
  printLabelsFail,
  cancelShipments,
  cancelShipmentsSuccess,
  cancelShipmentsFail,
  searchShipments,
  selectShipments,
})
export type ShipmentsListActions = typeof all
