import Axios from 'axios'
import { getBearerToken } from './token'

const laravelClient = Axios.create({
  headers: {
    Authorization: `Bearer ${getBearerToken()}`,
  },
})

export default laravelClient
