/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import * as Sentry from '@sentry/browser'

import get from 'lodash/get'
import laravelClient from '../../../../utils/laravel/client'

import Notifications from '../../../../contexts/Notifications'
import ManualShipment from '.'
import { reducer, initialState } from './reducer'
import * as actions from './actions'
import { Shipment, Timeslot } from './typings'

interface Props {
  children: React.ReactNode
}

const ManualShipmentProvider: React.FC<Props> = ({ children }: Props) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const { addNotification } = React.useContext(Notifications)

  const addShipment = React.useCallback(
    async (shipment: Shipment) => {
      dispatch(actions.createShipment())
      try {
        const response = await laravelClient.post<any>(
          'api/manual-shipment',
          shipment,
        )
        dispatch(actions.createShipmentSuccess())
        addNotification(response.data.message, 'success')
      } catch (axiosError) {
        dispatch(actions.createShipmentFail({ error: axiosError }))
        if (get(axiosError, 'response.status') === 422) {
          addNotification(
            `Error: ${get(axiosError, 'response.message')}`,
            'error',
          )
        }
        Sentry.captureException(axiosError)
        throw axiosError
      }
    },
    [addNotification],
  )

  const fetchTimeslot = React.useCallback(
    async (postCode: string, country: string) => {
      dispatch(actions.fetchDeliveryDate())
      try {
        const response = await laravelClient.post<Timeslot[]>(
          'api/delivery-date',
          { postCode, country },
        )
        dispatch(actions.fetchDeliveryDateSuccess({ timeslot: response.data }))
      } catch (axiosError) {
        dispatch(actions.fetchDeliveryDateFail({ error: axiosError }))
        Sentry.captureException(axiosError)
        throw axiosError
      }
    },
    [],
  )

  const contextValue = React.useMemo(
    () => ({
      ...state,
      addShipment,
      fetchTimeslot,
    }),
    [state, addShipment, fetchTimeslot],
  )

  return (
    <ManualShipment.Provider value={contextValue}>
      {children}
    </ManualShipment.Provider>
  )
}
export default ManualShipmentProvider
