import React from 'react'

import { useTranslation } from 'react-i18next'

import ActionButton from '../../../../components/ActionButton'
import classes from './BackButton.module.scss'

const BackButton: React.FC = () => {
  const [t] = useTranslation('MANUAL_SHIPMENT')
  const handleBackToShipmentsPage = () => {
    window.location.replace('/trunkrs-shipments')
  }

  return (
    <ActionButton
      classNames={[classes.go_back]}
      onClick={handleBackToShipmentsPage}
      disableRipple
    >
      {t('BACK_TO_SHIPMENTS')}
    </ActionButton>
  )
}

export default BackButton
