import React from 'react'

import { useTranslation } from 'react-i18next'

import ShipmentForm, { ManualShipmentFields } from '../ShipmentForm'
import PageHeader from '../../../../components/PageHeader'
import BackButton from '../BackButton'

import ManualShipmentContext from '../../store/ManualShipment'

import classes from './MainContent.module.scss'
import ProgressIndicator from '../../../../components/ProgressIndicator'

const MainContent: React.FC = () => {
  const { addShipment, creatingShipment, successShipment } = React.useContext(
    ManualShipmentContext,
  )
  const [t] = useTranslation('MANUAL_SHIPMENT')

  const handleCreateShipment = React.useCallback(
    (shipment: ManualShipmentFields) => {
      addShipment(shipment)
    },
    [addShipment],
  )

  const progressText = creatingShipment ? t('CREATING_SHIPMENT') : t('LOADING')

  return (
    <>
      {creatingShipment && <ProgressIndicator progressText={progressText} />}
      <div className={classes.header_wrapper}>
        <BackButton />
        <PageHeader title={t('MANUAL_SHIPMENT_TITLE')} subtitle="" />
      </div>
      <ShipmentForm
        successShipment={successShipment}
        onCreateShipment={handleCreateShipment}
      />
    </>
  )
}

export default MainContent
