import { Color } from '@material-ui/lab/Alert'
import uniqId from 'lodash/uniqueId'
import * as actions from './actions'

interface Notification {
  id: string
  message: string
  severity: Color
  duration: number
}

interface State {
  notifications: Notification[]
}

export const initialState: State = {
  notifications: [],
}

export const reducer = (
  state: State,
  action: actions.NotificationActions,
): State => {
  switch (action.type) {
    case actions.addNotification.type: {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            id: uniqId(),
            message: (action as any).message,
            severity: (action as any).severity,
            duration: (action as any).duration,
          },
        ],
      }
    }
    case actions.removeNotification.type: {
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== (action as any).id,
        ),
      }
    }
    default: {
      return state
    }
  }
}
