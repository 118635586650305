import orderBy from 'lodash/orderBy'
import get from 'lodash/get'

import { Configuration } from '../Configuration/typings'

import { defaultMappings, reverseMappingNames } from '.'
import { IMapping } from './typings'

export const createNewMappings = (
  mappings: IMapping[],
  newMapping: IMapping,
): IMapping[] => {
  const existing =
    newMapping.index! >= 0 &&
    mappings.find((mapping) => mapping.index === newMapping.index)
  const mappingsWOChanges = mappings.filter(
    (mapping) =>
      mapping.name !== newMapping.name &&
      (!existing || mapping.index !== existing.index),
  )

  const newMappings = existing
    ? [...mappingsWOChanges, newMapping, { ...existing, index: undefined }]
    : [...mappingsWOChanges, newMapping]

  return orderBy(newMappings, 'order')
}

export const createMappingsFromConfig = (
  config: Configuration | null,
): IMapping[] => {
  if (!config) {
    return defaultMappings
  }

  return defaultMappings.map((mapping) => {
    const index = config[get(reverseMappingNames, mapping.name)]

    if (index === undefined) {
      return mapping
    }
    return { ...mapping, index }
  })
}
