import * as React from 'react'
import clsx from 'clsx'

import styles from './PageContainer.module.scss'

export interface IPageContainerProps {
  children: React.ReactNode
}

const PageContainer: React.FunctionComponent<IPageContainerProps> = ({
  children,
}) => <div className={clsx('trunkrs-wrapper', styles.wrapper)}>{children}</div>

export default PageContainer
