import React from 'react'
import { useTranslation } from 'react-i18next'

import ShipmentStates from '../../../../constants/ShipmentStates'
import ShipmentsListContext from '../../store/ShipmentsList'

import PageHeader from '../../../../components/PageHeader'
import ProgressIndicator from '../../../../components/ProgressIndicator'

import SearchInput from '../SearchInput'
import ActionButtons from '../ActionButtons'
import { findProgressText } from './helpers'

import VirtualizedTable from '../VirtualizedTable'
import NoShipmentsAvailable from '../NoShipmentsAvailable'

import classes from './MainContent.module.scss'
import Filters from '../Filters'

const MainContent: React.FC = () => {
  const {
    fetchShipmentList,
    fetchingShipments,
    filteredShipments,
    selectedShipments,
    cancellingShipments,
    printingLabels,
    printLabels,
    printLabelsCombined,
    cancelShipments,
    toggleSelection,
    setSelection,
  } = React.useContext(ShipmentsListContext)

  const [t] = useTranslation('SHIPMENTS_LIST')

  const isProcessing =
    fetchingShipments || printingLabels || cancellingShipments

  React.useEffect(() => {
    fetchShipmentList()
  }, [fetchShipmentList])

  const shipmentsNotCancelled = React.useMemo(
    () =>
      filteredShipments.filter(
        (shipment) => shipment.stateLabel !== ShipmentStates.Cancelled,
      ),
    [filteredShipments],
  )

  const toggleAllShipments = React.useCallback(() => {
    if (selectedShipments.length) {
      setSelection()
    } else {
      setSelection(
        shipmentsNotCancelled.map((shipment) => shipment.trunkrsNumber),
      )
    }
  }, [selectedShipments.length, setSelection, shipmentsNotCancelled])

  const areAllShipmentsSelected =
    !!selectedShipments.length &&
    selectedShipments.length === shipmentsNotCancelled.length

  return (
    <>
      {isProcessing && (
        <ProgressIndicator
          progressText={t(findProgressText(fetchingShipments, printingLabels))}
        />
      )}
      <div className={classes.main_container}>
        <PageHeader
          title={t('SHIPMENTS_TABLE_TITLE')}
          subtitle={t('SHIPMENTS_SUB_TITLE')}
        />

        <SearchInput />

        <div className={classes.action_wrapper}>
          <Filters />
          <ActionButtons
            isShipmentsSelected={!!selectedShipments.length}
            onPrintLabels={printLabels}
            onPrintLabelsCombined={printLabelsCombined}
            onCancel={cancelShipments}
          />
        </div>

        {filteredShipments && filteredShipments.length ? (
          <div className={classes.virtualized_wrapper}>
            <VirtualizedTable
              areAllShipmentsSelected={areAllShipmentsSelected}
              onToggleShipment={toggleSelection}
              selectedShipments={selectedShipments}
              toggleAllShipments={toggleAllShipments}
              shipments={filteredShipments}
            />
          </div>
        ) : (
          <NoShipmentsAvailable />
        )}
      </div>
    </>
  )
}

export default MainContent
