import * as React from 'react'
import get from 'lodash/get'
import * as Sentry from '@sentry/browser'

import {
  defaultMappings,
  IMappingContext,
  MappingContext,
  reverseMappingNames,
} from '.'
import { ConfigurationContext } from '../Configuration'
import { createMappingsFromConfig, createNewMappings } from './helpers'
import { IMapping } from './typings'

export interface IMappingsProviderProps {
  children: React.ReactNode
}

const MappingsProvider: React.FunctionComponent<IMappingsProviderProps> = ({
  children,
}) => {
  const [isLoading] = React.useState<boolean>(false)
  const [isUniqueOrderRef, setIsUniqueOrderRef] = React.useState(true)
  const [mappings, setMappings] = React.useState(defaultMappings)

  const { setField, loadConfig } = React.useContext(ConfigurationContext)

  const changeMapping = React.useCallback(
    (mapping: IMapping) => {
      const newMappings = createNewMappings(mappings, mapping)
      setMappings(newMappings)
      setField(get(reverseMappingNames, mapping.name), mapping.index!)
    },
    [mappings, setField],
  )

  const toggleMakeOrderRefUnique = React.useCallback(
    () => setIsUniqueOrderRef(!isUniqueOrderRef),
    [isUniqueOrderRef],
  )

  const loadMappings = React.useCallback(() => {
    loadConfig()
      .then((config) => setMappings(createMappingsFromConfig(config)))
      .catch(Sentry.captureException)
  }, [loadConfig])

  const contextValue: IMappingContext = React.useMemo(
    () => ({
      isLoading,
      isUniqueOrderRef,
      mappings,
      changeMapping,
      toggleMakeOrderRefUnique,
      loadMappings,
    }),
    [
      isLoading,
      isUniqueOrderRef,
      mappings,
      changeMapping,
      toggleMakeOrderRefUnique,
      loadMappings,
    ],
  )

  return (
    <MappingContext.Provider value={contextValue}>
      {children}
    </MappingContext.Provider>
  )
}

export default MappingsProvider
