import React from 'react'
import moment from 'moment'
import md5 from 'md5'
import { useTranslation } from 'react-i18next'

import { Table, Column, AutoSizer } from 'react-virtualized'
import { Shipment } from '../../store/ShipmentsList/typings'
import CheckBox from '../../../../components/CheckBox'
import StateSpan from '../../../../components/StateSpan'
import RowRenderer from './RowRenderer'

import classes from './VirtualizedTable.module.scss'
import './ReactVirtualizedOverride.scss'

interface VirtualizedTableProps {
  shipments: Shipment[]
  selectedShipments: string[]
  onToggleShipment: (trunksNr: string) => void
  areAllShipmentsSelected: boolean
  toggleAllShipments: () => void
}

const VirtualizedTable: React.FC<VirtualizedTableProps> = ({
  shipments,
  selectedShipments,
  onToggleShipment,
  areAllShipmentsSelected,
  toggleAllShipments,
}) => {
  const [t] = useTranslation('SHIPMENTS_LIST')

  const handleTrackAndTalkUrl = React.useCallback(
    (customerPostCode, trunkrsNumber) => {
      const cleanPostal = customerPostCode.toLowerCase().replace(' ', '')
      const verifier = md5(trunkrsNumber + cleanPostal)

      const location = `https://parcel.trunkrs.nl/${verifier}/${trunkrsNumber}`
      window.open(location, '_blank')
    },
    [],
  )

  const State = React.useCallback(
    ({ customerPostCode, trunkrsNumber, stateLabel }) => (
      <StateSpan
        shipmentState={stateLabel}
        onClick={() => handleTrackAndTalkUrl(customerPostCode, trunkrsNumber)}
      />
    ),
    [handleTrackAndTalkUrl],
  )

  const deliveryDate = React.useCallback(
    ({ cellData }) =>
      moment(cellData).isValid()
        ? moment(cellData).utc().format('DD-MM-YYYY')
        : moment(cellData.substr(0, 10)).utc().format('DD-MM-YYYY'),
    [],
  )

  const handleToggleShipment = React.useCallback(
    (trunkrsNumber) => onToggleShipment(trunkrsNumber),
    [onToggleShipment],
  )

  const handleGetRowData = React.useCallback(
    ({ index }) => shipments[index],
    [shipments],
  )

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Table
          width={width - 35}
          height={height}
          headerHeight={60}
          rowHeight={60}
          rowCount={shipments.length}
          rowGetter={handleGetRowData}
          rowRenderer={RowRenderer}
          className={classes.table}
          headerClassName={classes.table_header}
          rowClassName={({ index }) => (index !== -1 ? classes.table_row : '')}
        >
          <Column
            label={t('TRUNKRS_NUMBER')}
            dataKey="trunkrsNumber"
            width={150}
          />
          <Column
            label={t('ORDER_NUMBER')}
            dataKey="orderReference"
            width={150}
          />
          <Column
            label={t('DATE_CREATED')}
            dataKey="created_at"
            cellRenderer={({ cellData }) =>
              moment(cellData).format('DD-MM-YY HH:mm')
            }
            width={180}
          />
          <Column
            label={t('DELIVERY_DATE')}
            dataKey="trunkrsDeliveryTime"
            cellRenderer={deliveryDate}
            width={150}
          />
          <Column
            label={t('RECIPIENT_NAME')}
            dataKey="customerName"
            width={250}
          />
          <Column
            label={t('ADDRESS')}
            dataKey="customerAddress"
            cellRenderer={({ cellData }) => cellData}
            width={300}
          />
          <Column
            label={t('POSTAL_CODE')}
            dataKey="customerPostCode"
            width={100}
          />
          <Column
            className={classes.status}
            label={t('STATUS')}
            dataKey=""
            cellRenderer={({ rowData }) => <State {...rowData} />}
            width={150}
          />
          <Column
            dataKey=""
            headerRenderer={() => (
              <CheckBox
                selected={areAllShipmentsSelected}
                onClick={toggleAllShipments}
                status=""
              />
            )}
            cellRenderer={({ rowData }) => (
              <CheckBox
                selected={
                  selectedShipments.includes(rowData.trunkrsNumber) &&
                  rowData.status !== 'cancelled'
                }
                status={rowData.status}
                onClick={() => handleToggleShipment(rowData.trunkrsNumber)}
              />
            )}
            width={45}
          />
        </Table>
      )}
    </AutoSizer>
  )
}

export default VirtualizedTable
