import React from 'react'

import { useTranslation } from 'react-i18next'

import { withFormik, Form, FormikProps } from 'formik'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import FormikInput from '../../../../components/FormikInput'
import FormikSelect from '../../../../components/FormikSelect'
import classes from './ShipmentForm.module.scss'
import DeliveryOption from '../DeliveryOption'
import ActionButtons from '../ActionButtons'
import ManualShipmentSchema from './schema'

import ManualShipmentContext from '../../store/ManualShipment'

export interface ManualShipmentFields {
  orderNumber: string
  companyName: string
  recipientName: string
  emailAdd: string
  phoneNumber: string
  houseNumber: string
  postalCode: string
  streetName: string
  city: string
  country: string
  delivery: string
}

interface ManualShipmentProps {
  onCreateShipment: (shipment: ManualShipmentFields) => void | Promise<void>
  successShipment: boolean
}

const ManualShipment: React.FC<
  ManualShipmentProps & FormikProps<ManualShipmentFields>
> = ({ resetForm, successShipment, values }) => {
  const { fetchTimeslot } = React.useContext(ManualShipmentContext)
  React.useEffect(() => {
    if (successShipment) {
      resetForm()
    }
  }, [resetForm, successShipment])

  React.useEffect(() => {
    if (values.postalCode.length >= 4 && values.country.length >= 2) {
      fetchTimeslot(values.postalCode, values.country).then()
    }
  }, [fetchTimeslot, values.country, values.postalCode])

  const [t] = useTranslation('MANUAL_SHIPMENT')
  return (
    <Form>
      <Grid item md={8} className={classes.form_body}>
        <Grid container>
          <Grid item md={12} style={{ marginBottom: 24 }}>
            <span>{t('REQUIRED_INFORMATION')}</span>
          </Grid>
          <Grid item md={6}>
            <FormikInput
              type="text"
              className={classes.trunkrs_api_input}
              name="orderNumber"
              label={t('ORDER_REFERENCE')}
            />
          </Grid>
          <Grid item md={12}>
            <FormikInput
              type="text"
              className={classes.trunkrs_api_input}
              name="companyName"
              label={t('COMPANY_NAME')}
              optional
            />
          </Grid>
          <Grid item md={12}>
            <FormikInput
              type="text"
              className={classes.trunkrs_api_input}
              name="recipientName"
              label={t('RECIPIENT_NAME')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <FormikInput
              type="email"
              className={classes.trunkrs_api_input}
              name="emailAdd"
              label={t('EMAIL_ADDRESS')}
            />
          </Grid>
          <Grid item md={6}>
            <FormikInput
              type="text"
              className={classes.trunkrs_api_input}
              name="phoneNumber"
              label={t('PHONE_NUMBER')}
              optional
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <FormikInput
              type="text"
              className={classes.trunkrs_api_input}
              name="houseNumber"
              label={t('HOUSE_NUMBER')}
            />
          </Grid>
          <Grid item md={6}>
            <FormikInput
              type="text"
              className={classes.trunkrs_api_input}
              name="postalCode"
              label={t('POSTAL_CODE')}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item md={12}>
            <FormikInput
              type="text"
              className={classes.trunkrs_api_input}
              name="streetName"
              label={t('STREET_NAME')}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <FormikInput
              type="text"
              className={classes.trunkrs_api_input}
              name="city"
              label={t('CITY')}
            />
          </Grid>
          <Grid item md={6}>
            <FormikSelect
              className={[classes.trunkrs_api_input]}
              name="country"
              label={t('COUNTRY')}
              items={[
                { value: ' ', label: t('SELECT_COUNTRY') },
                { value: 'NL', label: t('NL') },
                { value: 'BE', label: t('BE') },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container direction="column">
          <span>{t('DELIVERY_DATE')}</span>
          <span className="subtext">{t('DELIVERY_NOTICE')}</span>
        </Grid>
        <DeliveryOption name="delivery" />
        <Box display="flex" justifyContent="flex-end" width="100%">
          <ActionButtons />
        </Box>
      </Grid>
    </Form>
  )
}

const ShipmentForm = withFormik<ManualShipmentProps, ManualShipmentFields>({
  validationSchema: ManualShipmentSchema,
  handleSubmit(values, FormikBag) {
    const { onCreateShipment } = FormikBag.props
    onCreateShipment(values)
  },
  mapPropsToValues() {
    return {
      orderNumber: '',
      companyName: '',
      recipientName: '',
      emailAdd: '',
      phoneNumber: '',
      houseNumber: '',
      postalCode: '',
      streetName: '',
      city: '',
      country: ' ',
      delivery: '',
    }
  },
})(ManualShipment)

export default ShipmentForm
