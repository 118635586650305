import * as React from 'react'
import { ParseError, ParseResult } from 'papaparse'

export interface IFilesContext {
  parseFile: (
    file: File,
    hasHeaders: boolean,
    maxRows?: number,
  ) => Promise<ParseResult | null>
  reset: () => void
  isWorking: boolean
  current: File | null
  result: ParseResult | null
  error: ParseError | null
}

export const defaultFileContextValue: IFilesContext = {
  parseFile: () => Promise.reject<ParseResult>(),
  reset() {
    throw new Error('Not implemented!')
  },
  isWorking: false,
  current: null,
  result: null,
  error: null,
}

export const FileContext = React.createContext<IFilesContext>(
  defaultFileContextValue,
)
