import React from 'react'

import { InPageApp } from '../../utils/mounting/inPageApp'
import PageContainer from '../../components/PageContainer'

import '../../i18n'
import MainContent from './components/MainContent'
import ManualShipmentProvider from './store/ManualShipment/Provider'

@InPageApp('manual-shipment')
class ManualShipment extends React.Component {
  render(): JSX.Element {
    return (
      <ManualShipmentProvider>
        <PageContainer>
          <MainContent />
        </PageContainer>
      </ManualShipmentProvider>
    )
  }
}

export default ManualShipment
