export default (timeValue: number): string => {
  const hours = Math.floor(timeValue / 60)
  const minutes = timeValue - hours * 60

  return `${(hours < 10 ? '0' : '') + hours} : ${
    minutes === 0 ? '00' : minutes
  }`
}

export const getHour = (time: number): number => Math.floor(time / 60)
