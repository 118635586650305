import React from 'react'
import { useTranslation } from 'react-i18next'
// modal
import Dialog from '@material-ui/core/Dialog'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import ActionButton from '../../../../components/ActionButton'

import classes from './ActionButtons.module.scss'

interface ActionButtonsProp {
  isShipmentsSelected: boolean
  onCancel: () => void | Promise<void>
  onPrintLabels: () => void | Promise<void>
  onPrintLabelsCombined: () => void | Promise<void>
}

const ActionButtons: React.FC<ActionButtonsProp> = ({
  isShipmentsSelected,
  onCancel,
  onPrintLabels,
  onPrintLabelsCombined,
}) => {
  const [t] = useTranslation('ACTION_BUTTONS')
  const [openModal, setOpenModal] = React.useState(false)

  const handleCreateManualShipment = React.useCallback(() => {
    window.location.href = '/new-shipment'
  }, [])

  const handleOpenModal = React.useCallback(() => setOpenModal(true), [])

  const handleCloseModal = React.useCallback(() => setOpenModal(false), [])

  const handleContinueCancel = React.useCallback(() => {
    onCancel()
    handleCloseModal()
  }, [handleCloseModal, onCancel])

  return (
    <div className={classes.button_container}>
      <ActionButton
        disabled={false}
        classNames={['green']}
        onClick={handleCreateManualShipment}
      >
        {t('CREATE_SHIPMENT')}
      </ActionButton>
      <ActionButton
        disabled={!isShipmentsSelected}
        classNames={['blue']}
        onClick={onPrintLabels}
      >
        {t('PRINT_LABELS')}
      </ActionButton>
      <ActionButton
        disabled={!isShipmentsSelected}
        classNames={['blue']}
        onClick={onPrintLabelsCombined}
      >
        {t('PRINT_LABELS_COMBINED')}
      </ActionButton>
      <ActionButton
        disabled={!isShipmentsSelected}
        classNames={['red']}
        onClick={handleOpenModal}
      >
        {t('CANCEL_SHIPMENTS')}
      </ActionButton>

      <Dialog open={openModal} maxWidth="sm">
        <div className="modal-header">
          <span className="trunkrs-modal-title">{t('CANCEL_TITLE')}</span>
          <ActionButton
            onClick={handleCloseModal}
            disabled={false}
            classNames={[classes.trunkrs_close]}
          >
            <FontAwesomeIcon icon={faTimes} />
          </ActionButton>
        </div>
        <div className="modal-body">
          <span className="trunkrs-modal-body-text">
            {t('CANCEL_SHIPMENT_MODAL_TEXT')}
          </span>
        </div>
        <div className="modal-footer">
          <ActionButton
            onClick={handleCloseModal}
            disabled={false}
            classNames={[classes.trunkrs_no]}
          >
            {t('NO')}
          </ActionButton>
          <ActionButton
            onClick={handleContinueCancel}
            disabled={false}
            classNames={[classes.trunkrs_cancel]}
          >
            {t('YES')}
          </ActionButton>
        </div>
      </Dialog>
    </div>
  )
}

export default ActionButtons
