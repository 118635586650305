/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react'
import clsx from 'clsx'

import ButtonBase from '@material-ui/core/ButtonBase'

import styles from './SelectedFile.module.scss'

export interface ISelectedFileProps {
  isWorking: boolean
  file: File
  hasHeaders: boolean
  onToggleHeaders: (hasHeaders: boolean) => void
  onStartParsing: () => void
}

const SelectedFile: React.FunctionComponent<ISelectedFileProps> = ({
  isWorking,
  file,
  hasHeaders,
  onToggleHeaders,
  onStartParsing,
}) => (
  <div className={clsx('card', styles.card)}>
    <div className={clsx('card-body', styles.body)}>
      <i className={clsx('material-icons', styles.icon)}>insert_drive_file</i>
      <div className={styles.fileContainer}>
        <h5 className={clsx('card-title', styles.fileName)}>{file.name}</h5>

        <div className={clsx('form-check', styles.checkContainer)}>
          <input
            checked={hasHeaders}
            className={clsx('form-check-input', styles.input)}
            type="checkbox"
            id="has-header"
            onChange={() => onToggleHeaders(!hasHeaders)}
          />
          <label
            className={clsx('form-check-label', styles.label)}
            htmlFor="has-header"
          >
            The file contains headers
          </label>
        </div>
      </div>
    </div>
    <div className={clsx('card-footer text-muted', styles.footer)}>
      <ButtonBase
        disabled={isWorking}
        onClick={onStartParsing}
        className="btn btn-primary"
      >
        Import
      </ButtonBase>
    </div>
  </div>
)

export default SelectedFile
