enum ShipmentStates {
  ShipmentReceived = 'RC',
  ScannedAtCentralHub = 'MC',
  AssignedToTour = 'RA',
  ScannedAtSubDepot = 'AC',
  NotAcceptedByDriver = 'NA',
  AcceptedByDriver = 'CM',
  DriverNearRecipient = 'AD',
  Delivered = 'OK',
  DeliveredToNeighbour = 'DN',
  NotDelivered = 'ND',
  Cancelled = 'CA',
  NotArrived = 'EXCEPTION_SHIPMENT_NOT_ARRIVED',
  Investigate = 'EXCEPTION_SHIPMENT_INVESTIGATE',
  ReturnToSender = 'RETURN_SHIPMENT_TO_SENDER',
  DataProcessed = 'DATA_PROCESSED',
  ShipmentSorted = 'SHIPMENT_SORTED',
  ShipmentSortedAtSubDepot = 'SHIPMENT_SORTED_AT_SUB_DEPOT',
  DeclinedByDriver = 'EXCEPTION_SHIPMENT_DECLINED_BY_DRIVER',
  AcceptedDriver = 'SHIPMENT_ACCEPTED_BY_DRIVER',
  ShipmentDelivered = 'SHIPMENT_DELIVERED',
  ShipmentToNeighbour = 'SHIPMENT_DELIVERED_TO_NEIGHBOR',
  ShipmentNotDelivered = 'SHIPMENT_NOT_DELIVERED',
  CancelledBySender = 'EXCEPTION_SHIPMENT_CANCELLED_BY_SENDER',
  CancelledByTrunkrs = 'EXCEPTION_SHIPMENT_CANCELLED_BY_TRUNKRS',
}

export default ShipmentStates
