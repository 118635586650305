import * as React from 'react'
import Color from 'color'

import SmallMenu from '../../../../../../../components/SmallMenu'
import SmallMenuItem from '../../../../../../../components/SmallMenu/Item'

import { IMapping } from '../../../../../Context/Mappings/typings'

import styles from './LinkTableHeader.module.scss'

export interface ILinkTableHeaderProps {
  name: string
  columnIndex: number
  mappings: IMapping[]
  onChangeMapping: (mapping: IMapping) => void
}

const LinkTableHeader: React.FunctionComponent<ILinkTableHeaderProps> = ({
  name,
  columnIndex,
  mappings,
  onChangeMapping,
}) => {
  const selected = React.useMemo(
    () => mappings.find((mapping) => mapping.index === columnIndex),
    [mappings, columnIndex],
  )
  const selectStyle = React.useMemo<React.CSSProperties>(
    () =>
      selected
        ? {
            backgroundColor: selected.color,
            color: Color(selected.color).isDark() ? '#FFF' : '#333',
          }
        : {},
    [selected],
  )
  const handleMenuSelection = React.useCallback(
    (mapping) =>
      onChangeMapping({
        ...mapping,
        index: mapping.index === columnIndex ? undefined : columnIndex,
      }),
    [onChangeMapping, columnIndex],
  )

  return (
    <th className={styles.header} style={selectStyle}>
      <p className={styles.text}>{name}</p>
      <SmallMenu
        className={styles.menu}
        selected={selected}
        onSelected={handleMenuSelection}
      >
        {mappings.map((mapping) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const hoverStyle = React.useMemo(
            () => ({
              backgroundColor: mapping.color,
              color: Color(mapping.color).isDark() ? '#FFF' : '#333',
            }),
            [mapping.color],
          )

          return (
            <SmallMenuItem
              key={mapping.name}
              value={mapping}
              hoverStyle={hoverStyle}
            >
              <p className={styles.mappingName}>{mapping.name}</p>
            </SmallMenuItem>
          )
        })}
      </SmallMenu>
    </th>
  )
}

export default LinkTableHeader
