import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// eslint-disable-next-line @typescript-eslint/no-var-requires,import/no-dynamic-require
const resources = require('i18next-resource-store-loader' +
  '?overrideDir=override&baseDir=locales&include=\\.json$!../i18n/index.ts')

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: ['en', 'nl'],
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
