// eslint-disable-next-line import/prefer-default-export
export const findProgressText = (
  isFetchingShipments: boolean,
  isPrintingLabels: boolean,
): string => {
  if (isFetchingShipments) {
    return 'LOADING'
  }
  if (isPrintingLabels) {
    return 'PRINTING_LABELS'
  }

  return 'CANCELLING_SHIPMENTS'
}
