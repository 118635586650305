import React from 'react'
import { useTranslation } from 'react-i18next'
import classes from './NoShipmentsAvailable.module.scss'

const NoShipmentsAvailable: React.FC = () => {
  const [t] = useTranslation('SHIPMENTS_LIST')
  return (
    <div className={classes.no_shipment}>
      <img src="/storage/images/no-shipment-icon.svg" alt={t('NO_SHIPMENTS')} />
      <p className={classes.no_shipment_text}>{t('NO_SHIPMENTS')}</p>
    </div>
  )
}

export default NoShipmentsAvailable
