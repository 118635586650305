import React from 'react'

import { Color } from '@material-ui/lab/Alert'

interface State {
  addNotification: (message: string, severity: Color, duration?: number) => void
}

const Notifications = React.createContext<State>({
  addNotification() {
    throw new Error('Not implemented!')
  },
})

export default Notifications
