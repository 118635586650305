import { Color } from '@material-ui/lab/Alert'
import { createAction, props, union } from '../../utils/actions'

export const addNotification = createAction(
  'ADD_NOTIFICATION',
  props<{ message: string; severity: Color; duration: number }>(),
)

export const removeNotification = createAction(
  'REMOVE_NOTIFICATION',
  props<{ id: string }>(),
)

const All = union({
  addNotification,
  removeNotification,
})

export type NotificationActions = typeof All
