import * as React from 'react'
import { IShipment } from './typings'

export interface IShipmentSaveResult {
  saved: number
  total: number
}

export interface IShipmentsContext {
  shipments: IShipment[] | null
  failed: IShipment[]
  isCompleted: boolean
  mapShipments: () => Promise<void>
  saveShipments: (
    onProgress: (progress: IShipmentSaveResult) => void,
  ) => Promise<void>
  downloadFailed: () => void
}

export const ShipmentsContext = React.createContext<IShipmentsContext>({
  shipments: null,
  failed: [],
  isCompleted: false,
  mapShipments: () => Promise.resolve(),
  // eslint-disable-next-line require-yield
  saveShipments(): Promise<void> {
    return Promise.reject(new Error('Not implemented'))
  },
  downloadFailed: () => null,
})
