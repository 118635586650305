import * as React from 'react'
import { useDropzone } from 'react-dropzone'
import clsx from 'clsx'
import groupBy from 'lodash/groupBy'

import styles from './DropZone.module.scss'

export interface IDropZoneProps {
  onDropFile: (files: File[], rejectedFiles: File[]) => void | Promise<void>
}

const acceptedFileTypes = ['text/csv', 'application/vnd.ms-excel']

const checkFiles = (files: File[]): [File[], File[]] => {
  const { [true as any]: accepted, [false as any]: rejected } = groupBy(
    files,
    (file) => {
      return file.name.endsWith('.csv') || acceptedFileTypes.includes(file.type)
    },
  )

  return [accepted, rejected]
}

const DropZone: React.FunctionComponent<IDropZoneProps> = ({ onDropFile }) => {
  const handleDropFiles = React.useCallback(
    (files) => onDropFile(...checkFiles(files)),
    [onDropFile],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDropFiles,
    preventDropOnDocument: true,
    multiple: false,
  })

  return (
    <div
      {...getRootProps()}
      className={clsx(
        'card bg-light mb-3 text-center',
        styles.dropZone,
        isDragActive && styles.active,
      )}
    >
      <input {...getInputProps()} />
      <div className={clsx('card-body', styles.body)}>
        <h5 className={clsx('card-text', styles.title)}>
          You can simply drop your CSV file here.
        </h5>
        <div className="btn btn-primary btn-lg">
          <i className={clsx('material-icons', styles.btnIcon)}>cloud_upload</i>
          Or upload one
        </div>
      </div>
    </div>
  )
}

export default DropZone
