import React from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'

import { useField } from 'formik'
import FormLabel from '@material-ui/core/FormLabel'
import clsx from 'clsx'
import classes from './FormikSelect.module.scss'

interface itemsProps {
  value: string
  label: string
}

interface FormikSelectProps {
  label: string
  name: string
  items: itemsProps[]
  className?: string[]
}
const FormikSelect: React.FC<FormikSelectProps> = ({
  label,
  name,
  items,
  className,
}) => {
  const [field, meta] = useField(name)
  const hasError = !!(meta.touched && meta.error)
  return (
    <FormControl error={hasError} fullWidth>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select
        {...field}
        id={name}
        displayEmpty
        className={clsx('form-control', classes.select, className)}
        value={meta.value}
      >
        {items.map((item) => (
          <MenuItem
            className={classes.select_item}
            key={item.value}
            value={item.value}
          >
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <Fade in={hasError}>
        <Box>
          <FormHelperText error className={classes.error_text}>
            {meta.error || ' '}
          </FormHelperText>
        </Box>
      </Fade>
    </FormControl>
  )
}

export default FormikSelect
