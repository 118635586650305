import React from 'react'

import { Shipment } from './typings'
import { initialState, State } from './reducer'

export interface ManualShipmentState extends State {
  addShipment(shipment: Shipment): Promise<void>
  fetchTimeslot(postCode: string, country: string): Promise<void>
}

const ManualShipment = React.createContext<ManualShipmentState>({
  ...initialState,
  addShipment: () => Promise.reject(new Error('Not implemented!')),
  fetchTimeslot: () => Promise.reject(new Error('Not implemented!')),
})

export default ManualShipment
