import * as React from 'react'

import PageContainer from '../../components/PageContainer'

import { InPageApp } from '../../utils/mounting/inPageApp'

import ConfigProvider from './Context/Configuration/Provider'
import FileProvider from './Context/Files/Provider'
import MappingsProvider from './Context/Mappings/Provider'
import ShipmentsProvider from './Context/Shipments/Provider'
import Pages from './enum'

import LinkPage from './Pages/Link'
import ReviewPage from './Pages/Review'
import UploadPage from './Pages/Upload'
import FailedPage from './Pages/Failed'

import '../../i18n'

import 'react-perfect-scrollbar/dist/css/styles.css'
import styles from './ImportCSV.module.scss'

import './icons'

export interface IImportCSVState {
  page: Pages
}

@InPageApp('trunkrs-uploadCSV')
class ImportCSV extends React.Component<any, IImportCSVState> {
  constructor(props: never) {
    super(props)

    this.state = {
      page: Pages.upload,
    }
  }

  public handleChangePage = (page: Pages): void => {
    this.setState({ page })
  }

  public renderContent = (): JSX.Element => {
    const { page } = this.state

    switch (page) {
      case Pages.link:
        return <LinkPage onChangePage={this.handleChangePage} />
      case Pages.preview:
        return <ReviewPage onChangePage={this.handleChangePage} />
      case Pages.failed:
        return <FailedPage />
      default:
        return <UploadPage onChangePage={this.handleChangePage} />
    }
  }

  public render(): JSX.Element {
    return (
      <PageContainer>
        <div className={styles.innerContainer}>
          <ConfigProvider>
            <FileProvider>
              <MappingsProvider>
                <ShipmentsProvider>{this.renderContent()}</ShipmentsProvider>
              </MappingsProvider>
            </FileProvider>
          </ConfigProvider>
        </div>
      </PageContainer>
    )
  }
}

export default ImportCSV
