import * as Sentry from '@sentry/browser'
import { SENTRY_DSN, ENVIRONMENT_NAME } from './constants'
import NotificationsProvider from './contexts/Notifications/Provider'
import ThemeProvider from './contexts/Theme/Provider'

import * as serviceWorker from './serviceWorker'
import MiniAppRegistry from './utils/mounting/miniAppRegistry'

import 'react-virtualized/styles.css'
import './global.scss'

import './apps'

Sentry.init({
  dsn: SENTRY_DSN,
  environment: ENVIRONMENT_NAME,
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

MiniAppRegistry.addProvider(NotificationsProvider)
  .addProvider(ThemeProvider)
  .mountApps()
