import appRegistry, { MountPredicate } from './miniAppRegistry'

export function InPageApp(id: string): ClassDecorator
export function InPageApp(predicate: MountPredicate): ClassDecorator
export function InPageApp(
  predicateOrId: MountPredicate | string,
): ClassDecorator {
  return (target: any) => {
    appRegistry.addApplication(predicateOrId as any, target)
  }
}
