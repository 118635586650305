import React from 'react'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faFilter,
  faTimes,
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons'

import Fade from '@material-ui/core/Fade'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import MomentUtils from '@date-io/moment'

import ActionButton from '../../../../components/ActionButton'
import DateTimeCreatedFilter from '../DateTimeCreatedFilter'
import DeliveryDateFilter from '../DeliveryDateFilter'
import TimeRangeSlider from '../TimeRangeSlider'

import ShipmentsList from '../../store/ShipmentsList'

import classes from './Filters.module.scss'
import './MuiPickerOverride.scss'

const Filters: React.FC = () => {
  const [t] = useTranslation('FILTERS')
  const [showFilter, setShowFilter] = React.useState(false)
  const [showDeliveryDateFilter, setShowDeliveryDateFilter] =
    React.useState(false)
  const [showCreatedDateTimeFilter, setShowCreatedDateTimeFilter] =
    React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState<moment.Moment>(
    moment(),
  )
  const [selectedDateTime, setSelectedDateTime] = React.useState<moment.Moment>(
    moment(),
  )
  const { filterByDate, filterByDateTime } = React.useContext(ShipmentsList)

  const handleFilterClick = React.useCallback(() => {
    filterByDate('')
    setSelectedDate(moment())
    filterByDateTime('')
    setSelectedDateTime(moment())
    setShowFilter(!showFilter)
    setShowDeliveryDateFilter(false)
    setShowCreatedDateTimeFilter(false)
  }, [filterByDate, filterByDateTime, showFilter])

  const handleDeliveryDateFilter = React.useCallback(() => {
    setShowDeliveryDateFilter(!showDeliveryDateFilter)
    setShowCreatedDateTimeFilter(false)
    filterByDate('')
    filterByDateTime('')
    setSelectedDate(moment())
    setSelectedDateTime(moment())
  }, [filterByDate, filterByDateTime, showDeliveryDateFilter])

  const handleCreatedDateTimeFilter = React.useCallback(() => {
    setShowCreatedDateTimeFilter(!showCreatedDateTimeFilter)
    setShowDeliveryDateFilter(false)
    filterByDate('')
    filterByDateTime('')
    setSelectedDate(moment())
    setSelectedDateTime(moment())
  }, [filterByDate, filterByDateTime, showCreatedDateTimeFilter])

  const handleFilterByDate = React.useCallback(
    (dateString: string) => filterByDate(dateString),
    [filterByDate],
  )

  const handleFilterByDateTime = React.useCallback(
    (dateTimeString: string) => filterByDateTime(dateTimeString),
    [filterByDateTime],
  )

  const handleFilterByTime = React.useCallback(
    debounce((newValues: string) => handleFilterByDateTime(newValues), 250),
    [],
  )

  const handleDateChange = React.useCallback(
    (date: moment.Moment) => {
      setSelectedDate(date)
      handleFilterByDate(moment(date).format('YYYY-MM-DD'))
    },
    [handleFilterByDate],
  )

  const handleDateTimeChange = React.useCallback((date: moment.Moment) => {
    setSelectedDateTime(date)
  }, [])

  return (
    <div className={classes.filter_container}>
      <ActionButton
        disableRipple={false}
        disabled={false}
        classNames={[classes.filter_btn]}
        onClick={handleFilterClick}
      >
        <FontAwesomeIcon
          icon={!showFilter ? faFilter : faTimes}
          className={classes.filter_icon}
        />
        <Typography component="span" className={classes.filterText}>
          {t('FILTER_TEXT')}
        </Typography>
      </ActionButton>
      <Fade in={showFilter}>
        <div className={classes.date_button}>
          {!showCreatedDateTimeFilter && (
            <Button
              className={classes.btn}
              variant="contained"
              type="button"
              color="primary"
              onClick={handleDeliveryDateFilter}
            >
              <FontAwesomeIcon
                icon={!showDeliveryDateFilter ? faCalendarAlt : faTimes}
                className={classes.filter_icon}
              />
              <Typography className={classes.text} component="span">
                {t('BY_DELIVERY_DATE')}
              </Typography>
            </Button>
          )}
          {!showDeliveryDateFilter && (
            <Button
              className={classes.btn}
              variant="contained"
              type="button"
              color="primary"
              onClick={handleCreatedDateTimeFilter}
            >
              <FontAwesomeIcon
                icon={!showCreatedDateTimeFilter ? faCalendarAlt : faTimes}
                className={classes.filter_icon}
              />
              <Typography
                className={clsx(classes.text, classes.textSmallHide)}
                component="span"
              >
                {t('BY_DATE_TIME_CREATED')}
              </Typography>
              <Typography className={classes.textSmall} component="span">
                {t('CREATED')}
              </Typography>
            </Button>
          )}
        </div>
      </Fade>
      {(showDeliveryDateFilter || showCreatedDateTimeFilter) && (
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          {showDeliveryDateFilter ? (
            <DeliveryDateFilter
              selectedDate={selectedDate}
              handleDateChange={handleDateChange}
            />
          ) : (
            <>
              <DateTimeCreatedFilter
                selectedDate={selectedDateTime}
                handleDateChange={handleDateTimeChange}
              />
              <TimeRangeSlider
                showTimeFilter={showCreatedDateTimeFilter}
                selectedDateTime={selectedDateTime}
                handleFilterByTime={handleFilterByTime}
              />
            </>
          )}
        </MuiPickersUtilsProvider>
      )}
    </div>
  )
}

export default Filters
