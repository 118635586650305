import React from 'react'

import { Color } from '@material-ui/lab/Alert'

import NotificationBar from '../../components/NotificationBar'
import Notifications from './index'
import * as actions from './actions'
import { initialState, reducer } from './reducer'

interface Props {
  children: React.ReactNode
}

const NotificationsProvider: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const addNotification = React.useCallback(
    (message: string, severity: Color, duration = 5000) => {
      dispatch(
        actions.addNotification({
          message,
          severity,
          duration,
        }),
      )
    },
    [],
  )

  const removeNotification = React.useCallback((id: string) => {
    dispatch(actions.removeNotification({ id }))
  }, [])

  const contextValue = React.useMemo(
    () => ({
      ...state,
      addNotification,
      removeNotification,
    }),
    [state, addNotification, removeNotification],
  )

  return (
    <Notifications.Provider value={contextValue}>
      {React.useMemo(
        () =>
          state.notifications.map((notification) => (
            <NotificationBar
              key={notification.id}
              message={notification.message}
              duration={notification.duration}
              severity={notification.severity}
              onClose={() => removeNotification(notification.id)}
            />
          )),
        [state.notifications, removeNotification],
      )}
      {children}
    </Notifications.Provider>
  )
}

export default NotificationsProvider
