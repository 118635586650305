import React from 'react'
import clsx from 'clsx'

import { defaultTableRowRenderer, TableRowRenderer } from 'react-virtualized'
import classes from './RowRenderer.module.scss'

const RowRenderer: TableRowRenderer = ({ style, columns, ...props }) => {
  const { width, ...restStyle } = style
  return defaultTableRowRenderer({
    style: { width: width - 10, ...restStyle },
    columns: (
      <div className={clsx(classes.row, 'ReactVirtualized__Table__row')}>
        {columns}
      </div>
    ) as unknown as any[],
    ...props,
  })
}

export default RowRenderer
