import { Palette, PaletteOptions } from '@material-ui/core/styles/createPalette'

interface TrunkrsColors {
  primary: {
    trunkrsIndigo: string
    trunkrsGreen: string
    actionBlue: string
    brightCrimson: string
  }
  secondary: {
    autumnYellow: string
    royalOrange: string
  }
  fades: {
    trunkrsIndigo: string
    trunkrsGreen: string
    actionBlue: string
    brightCrimson: string
    autumnYellow: string
    royalOrange: string
  }
  grey: {
    darkGrey: string
    lightGrey: string
    background: string
    softBorder: string
    scrimOverlay: string
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    trunkrs: TrunkrsColors
  }

  interface PaletteOptions {
    trunkrs?: TrunkrsColors
  }
}

const trunkrs: TrunkrsColors = {
  primary: {
    trunkrsIndigo: '#201047',
    trunkrsGreen: '#00D763',
    actionBlue: '#13D2FC',
    brightCrimson: '#FF5351',
  },
  secondary: {
    autumnYellow: '#FFD65A',
    royalOrange: '#FF8F5E',
  },
  fades: {
    trunkrsIndigo: '#8F87A2',
    trunkrsGreen: '#7FEBB0',
    actionBlue: '#88E8FD',
    brightCrimson: '#FFA8A7',
    autumnYellow: '#FFEAAC',
    royalOrange: '#FFC7AE',
  },
  grey: {
    darkGrey: '#888888',
    lightGrey: '#EDEDED',
    background: '#F4F7FC',
    softBorder: 'rgba(0,0,0,0.12)',
    scrimOverlay: 'rgba(0,0,0,0.50)',
  },
}

const colors: PaletteOptions = {
  type: 'light',

  primary: {
    main: trunkrs.primary.trunkrsIndigo,
  },

  secondary: {
    main: trunkrs.primary.trunkrsGreen,
  },

  success: {
    main: trunkrs.primary.trunkrsGreen,
  },

  error: {
    main: trunkrs.primary.brightCrimson,
  },

  warning: {
    main: trunkrs.secondary.royalOrange,
  },

  info: {
    main: trunkrs.primary.actionBlue,
  },

  background: {
    default: trunkrs.grey.background,
  },

  text: {
    primary: trunkrs.primary.trunkrsIndigo,
    secondary: trunkrs.grey.darkGrey,
    disabled: trunkrs.grey.lightGrey,
    hint: trunkrs.primary.actionBlue,
  },

  trunkrs,
}

export default colors
