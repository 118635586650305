import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles'

import typography from './typography'
import palette from './colors'
import shape from './shape'
import other from './other'

import overrides from './overrides'
import props from './props'

const theme: ThemeOptions = {
  ...other,
  palette,
  typography,
  overrides,
  props,
  shape,
}

export default createMuiTheme(theme)
