import React from 'react'

import { useTranslation } from 'react-i18next'
import withStyles from '@material-ui/core/styles/withStyles'
import createStyles from '@material-ui/core/styles/createStyles'
import { Theme } from '@material-ui/core/styles'

import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MuiTooltip from '@material-ui/core/Tooltip'

import { MappingContext } from '../../../../Context/Mappings'

import styles from './UniqueCheck.module.scss'

const StyledCheckBox = withStyles(({ palette }: Theme) =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    checked: {
      color: palette.secondary.main,
    },
  }),
)(Checkbox)

const UniqueCheck: React.FC = () => {
  const [t] = useTranslation('CSV_UPLOAD')
  const { isUniqueOrderRef, toggleMakeOrderRefUnique } =
    React.useContext(MappingContext)

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12">
            <MuiTooltip
              title={
                <span className={styles.tooltip}>
                  {t('MakeOrderRefUnique')}
                </span>
              }
              arrow
              placement="right"
              className={styles.tooltip}
            >
              <FormControlLabel
                control={
                  <StyledCheckBox
                    name="uniqRef"
                    checked={isUniqueOrderRef}
                    onClick={toggleMakeOrderRefUnique}
                  />
                }
                label={
                  <span className={styles.uniqueRef}>
                    Enforce unicity of order references
                  </span>
                }
              />
            </MuiTooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UniqueCheck
