/* eslint-disable react-hooks/exhaustive-deps */

import * as React from 'react'
import clsx from 'clsx'

import { AutoSizer } from 'react-virtualized'
import ButtonBase from '@material-ui/core/ButtonBase'

import Pages from '../../enum'

import { IShipmentSaveResult, ShipmentsContext } from '../../Context/Shipments'

import PageHeader from '../../../../components/PageHeader'
import LoadingDialog from './components/LoadingDialog'
import SavingDialog from './components/SavingDialog'
import VirtualShipmentTable from '../../../../components/VirtualShipmentTable'

import styles from './ReviewPage.module.scss'

export interface IReviewPageProps {
  onChangePage: (page: Pages) => void
}

const ReviewPage: React.FunctionComponent<IReviewPageProps> = ({
  onChangePage,
}) => {
  const { shipments, failed, isCompleted, mapShipments, saveShipments } =
    React.useContext(ShipmentsContext)

  const [isLoading, setLoading] = React.useState(true)
  const [isSaving, setSaving] = React.useState(false)
  const [progress, setProgress] = React.useState<IShipmentSaveResult>({
    saved: 0,
    total: 0,
  })

  const handleGoBack = React.useCallback(
    () => onChangePage(Pages.link),
    [onChangePage],
  )
  const handleSave = React.useCallback((): Promise<void> => {
    setSaving(true)
    return saveShipments(setProgress)
  }, [saveShipments, setSaving])

  React.useEffect(() => {
    mapShipments()
      .then(() => setLoading(false))
      .catch(console.error)
  }, [])

  React.useEffect(() => {
    if (!isCompleted) {
      return
    }

    if (failed.length) {
      onChangePage(Pages.failed)
    } else {
      window.location.href = '/trunkrs-shipments'
    }
  }, [isCompleted, failed])

  return (
    <>
      <PageHeader
        title="Review your shipments"
        subtitle="Please take a moment to review your shipments."
      >
        <div className={styles.buttonContainer}>
          <ButtonBase className="btn btn-primary" onClick={handleGoBack}>
            Back
          </ButtonBase>
          <ButtonBase
            onClick={handleSave}
            className={clsx('btn btn-primary', styles.continueButton)}
          >
            Save
          </ButtonBase>
        </div>
      </PageHeader>

      <AutoSizer>
        {({ width, height }) => (
          <div style={{ width, height: height - 132 }}>
            {!isLoading && shipments && (
              <VirtualShipmentTable
                height={height - 132}
                shipments={shipments}
              />
            )}
          </div>
        )}
      </AutoSizer>

      <LoadingDialog open={isLoading} />
      <SavingDialog open={isSaving} {...progress} />
    </>
  )
}

export default ReviewPage
