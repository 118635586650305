import * as React from 'react'
import clsx from 'clsx'

export interface IDialogBaseProps {
  className?: string
  open?: boolean
  children: React.ReactNode
}

const showDialog = (node: HTMLElement): void => {
  $(node).modal('show')
}

const hideDialog = (node: HTMLElement): void => {
  $(node!).modal('hide')
}

const DialogBase: React.FC<IDialogBaseProps> = ({
  className,
  open,
  children,
}) => {
  const modalRef = React.useRef<HTMLDivElement>()

  const handleSetModalNode = React.useCallback(
    (modalNode: HTMLDivElement) => {
      modalRef.current = modalNode
      if (open && modalNode) showDialog(modalNode)
    },
    [open],
  )

  React.useEffect(() => {
    if (open) {
      showDialog(modalRef.current!)
    } else {
      hideDialog(modalRef.current!)
    }
  }, [open])

  React.useEffect(() => {
    return () => modalRef.current && hideDialog(modalRef.current)
  }, [])

  return (
    <div
      ref={handleSetModalNode}
      className="modal"
      data-keyboard={false}
      data-backdrop="static"
    >
      <div
        className={clsx('modal-dialog modal-dialog-centered', className)}
        role="document"
      >
        {children}
      </div>
    </div>
  )
}

export default DialogBase
