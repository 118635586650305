import React from 'react'
import clsx from 'clsx'

import ButtonBase from '@material-ui/core/ButtonBase'
import classes from './ActionButton.module.scss'

interface ActionButtonProps {
  classNames: string[]
  disabled?: boolean
  onClick?: () => void
  disableRipple?: boolean
}

enum colors {
  Red = 'red',
  Blue = 'blue',
  Green = 'green',
}

const ActionButton: React.FC<ActionButtonProps> = ({
  children,
  classNames,
  disabled,
  onClick,
  disableRipple,
}) => {
  return (
    <ButtonBase
      disableRipple={disableRipple}
      className={clsx(
        classes.btn,
        classNames,
        classNames.includes(colors.Red) && classes.red,
        classNames.includes(colors.Blue) && classes.blue,
        classNames.includes(colors.Green) && classes.green,
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </ButtonBase>
  )
}

export default ActionButton
