import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'

import decimalToTime, { getHour } from '../../../../utils/timeStringParser'
import classes from './TimeRange.module.scss'

export interface Props {
  showTimeFilter: boolean
  selectedDateTime: moment.Moment
  handleFilterByTime: (newValues: string) => void
}
const TimeRangeSlider: React.FC<Props> = ({
  showTimeFilter,
  selectedDateTime,
  handleFilterByTime,
}) => {
  const [t] = useTranslation('FILTERS')
  const [from, setFrom] = React.useState(1020)
  const [to, setTo] = React.useState(1320)

  React.useEffect(() => {
    setFrom(0)
    setTo(1440)
  }, [showTimeFilter])

  const dateTimeStringQuery = React.useCallback(
    (startTime: number, endTime: number) => {
      const timeRange = Array(getHour(endTime) - getHour(startTime) + 1)
        .fill(null)
        .map(
          (_: any, idx: number) =>
            `^${moment(selectedDateTime).format('YYYY-MM-DDT')}${
              getHour(startTime) + idx < 10 ? '0' : ''
            }${getHour(startTime) + idx}`,
        )

      return timeRange.join().replace(/,/g, ' | ')
    },
    [selectedDateTime],
  )

  React.useEffect(
    () => handleFilterByTime(dateTimeStringQuery(from, to)),
    [dateTimeStringQuery, from, handleFilterByTime, selectedDateTime, to],
  )

  const handleChange = React.useCallback(
    (event: any, newValues: number | number[]) => {
      if (!Array.isArray(newValues)) return
      setFrom(newValues[0])
      setTo(newValues[1])
    },
    [],
  )

  return (
    <Fade in={showTimeFilter}>
      <div className={classes.container}>
        <Typography component="span" className={classes.time}>
          {t('TIME_RANGE')}
        </Typography>
        <div className={classes.slider}>
          <Slider
            min={0}
            max={1440}
            value={[from, to]}
            step={10}
            onChange={handleChange}
          />
        </div>
        <Typography className={classes.timeWrapper}>
          <Typography component="span" className={classes.time}>
            From: {decimalToTime(from)}
          </Typography>
          <Typography component="span" className={classes.time}>
            To: {decimalToTime(to)}
          </Typography>
        </Typography>
      </div>
    </Fade>
  )
}

export default TimeRangeSlider
