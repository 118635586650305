import * as React from 'react'
import clsx from 'clsx'

import { Transition } from 'react-transition-group'
import { ENTERED, ENTERING } from 'react-transition-group/Transition'

import styles from './Fade.module.scss'

export interface IFadeProps {
  className?: string
  style?: Partial<CSSStyleDeclaration>
  in?: boolean
  appear?: boolean
  timeout?: number
  children: React.ReactElement
}

const transitionStyles: { [key: string]: Partial<CSSStyleDeclaration> } = {
  [ENTERING]: {
    opacity: '1',
  },
  [ENTERED]: {
    opacity: '1',
  },
}

const Fade: React.FC<IFadeProps> = ({
  className,
  style,
  in: inProp,
  appear,
  timeout = 0,
  children,
}) => (
  <Transition appear={appear} in={inProp} timeout={timeout}>
    {(state: string) => (
      <div
        className={clsx(styles.transition, className)}
        style={
          {
            visibility: state === 'exited' && !inProp ? 'hidden' : undefined,
            ...transitionStyles[state],
            ...style,
          } as any
        }
      >
        {children}
      </div>
    )}
  </Transition>
)

export default Fade
