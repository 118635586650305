import React from 'react'
import { useTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackspace, faSearch } from '@fortawesome/free-solid-svg-icons'
import ButtonBase from '@material-ui/core/ButtonBase'
import InputBase from '@material-ui/core/InputBase'
import ShipmentsList from '../../store/ShipmentsList'

import classes from './SearchInput.module.scss'

const SearchInput: React.FC = () => {
  const [t] = useTranslation('SEARCH_INPUT')
  const [searchText, setSearchText] = React.useState('')
  const { searchShipments } = React.useContext(ShipmentsList)

  const handleSearchShipment = React.useCallback(
    debounce(searchShipments, 250),
    [searchShipments],
  )

  const handleClearInput = React.useCallback(() => {
    setSearchText('')
    searchShipments('')
  }, [searchShipments])

  const handleSearchText = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      setSearchText(event.target.value)
      handleSearchShipment(event.target.value)
    },
    [handleSearchShipment],
  )

  return (
    <div className={classes.search_container}>
      <FontAwesomeIcon icon={faSearch} className={classes.search_icon} />
      <InputBase
        type="text"
        placeholder={t('SEARCH_SHIPMENT')}
        className={classes.search_me}
        onChange={handleSearchText}
        value={searchText}
      />

      {!!searchText && (
        <ButtonBase
          disableRipple
          className={classes.backspace_clear}
          onClick={handleClearInput}
        >
          <FontAwesomeIcon icon={faBackspace} />
          {t('CLEAR')}
        </ButtonBase>
      )}
    </div>
  )
}

export default SearchInput
