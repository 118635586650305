import * as React from 'react'

import styles from './PageHeader.module.scss'

export interface IPageHeaderProps {
  title: string
  subtitle: string
  children?: React.ReactNode
}

const PageHeader: React.FunctionComponent<IPageHeaderProps> = ({
  title,
  subtitle,
  children,
}) => (
  <div className={styles.container}>
    <div>
      <span className="trunkrs-shipments-page-title">{title}</span>
      <span className="trunkrs-shipments-subtitle">{subtitle}</span>
    </div>

    {children && <div className={styles.childContainer}>{children}</div>}
  </div>
)

export default PageHeader
