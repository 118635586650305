import React from 'react'
import clsx from 'clsx'

import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import { Theme } from '@material-ui/core/styles'

import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

interface RadioButtonProps {
  value: string
  label: string
  checked: boolean
  className?: string[]
}

const StyledRadioButton = withStyles(({ palette }: Theme) =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    checked: {
      color: palette.info.main,
    },
  }),
)(Radio)

const RadioButton: React.FC<RadioButtonProps> = ({
  value,
  label,
  checked,
  className,
}) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <StyledRadioButton color="default" disableRipple checked={checked} />
      }
      label={label}
      labelPlacement="end"
      className={clsx(className)}
    />
  )
}

export default RadioButton
