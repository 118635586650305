import React from 'react'
import moment from 'moment'
import { useField } from 'formik'

import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import RadioGroup from '@material-ui/core/RadioGroup'
import Typography from '@material-ui/core/Typography'
import RadioButton from '../../../../components/RadioButton'

import ManualShipmentContext from '../../store/ManualShipment'
import classes from './DeliveryOption.module.scss'

interface DeliveryOptionprops {
  name: string
}

const DeliveryOption: React.FC<DeliveryOptionprops> = ({ name }) => {
  moment.locale('de')
  const { timeslot, isFetchingTimeslot } = React.useContext(
    ManualShipmentContext,
  )

  const [field, meta] = useField(name)
  const hasError = !!(meta.touched && meta.error)

  const [sameDay, setSameDay] = React.useState<string>('')
  const [nextDay, setNextDay] = React.useState<string>('')

  React.useEffect(() => {
    if (timeslot && timeslot.length !== 0) {
      setSameDay(moment(timeslot[0].deliveryWindow.to.date).calendar())
      setNextDay(moment(timeslot[1].deliveryWindow.to.date).calendar())
    }
  }, [timeslot])

  return (
    <FormControl error={hasError} component="fieldset">
      {isFetchingTimeslot && <CircularProgress size={24} color="primary" />}
      {!isFetchingTimeslot && timeslot.length <= 0 && (
        <Typography component="span" className="subtext">
          We need postal code and country to determine the available delivery
          date
        </Typography>
      )}
      {!isFetchingTimeslot && timeslot.length > 0 && (
        <RadioGroup {...field} row name={name} value={meta.value}>
          <RadioButton value="0" label={sameDay} checked={meta.value === '0'} />
          <RadioButton value="1" label={nextDay} checked={meta.value === '1'} />
        </RadioGroup>
      )}
      <Fade in={hasError}>
        <Box>
          <FormHelperText error className={classes.error_text}>
            {meta.error || ' '}
          </FormHelperText>
        </Box>
      </Fade>
    </FormControl>
  )
}

export default DeliveryOption
