import React from 'react'

import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'

import ActionButton from '../../../../components/ActionButton'
import classes from './ActionButtons.module.scss'

const ActionButtons: React.FC = () => {
  const [t] = useTranslation('MANUAL_SHIPMENT')
  const handleBackToShipmentsPage = () => {
    window.location.replace('/trunkrs-shipments')
  }

  return (
    <>
      <ActionButton
        classNames={[classes.cancel]}
        onClick={handleBackToShipmentsPage}
      >
        {t('CANCEL')}
      </ActionButton>
      <Button type="submit" className={classes.btn_create_shipment}>
        {t('CREATE_SHIPMENT')}
      </Button>
    </>
  )
}

export default ActionButtons
