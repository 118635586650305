/* eslint-disable no-param-reassign,@typescript-eslint/no-explicit-any */
import * as React from 'react'
import clsx from 'clsx'

import ScrollContainer from 'react-perfect-scrollbar'

import styles from './SmallMenu.module.scss'

export interface ISmallMenuProps {
  className?: string
  selected: any
  onSelected: (selected: any) => void
  children: React.ReactNode
}

const hookChildren = (
  children: any,
  selected: any,
  onSelected: ISmallMenuProps['onSelected'],
): React.ReactNode[] =>
  React.Children.map(children, (child: React.ReactElement) =>
    React.cloneElement(child, {
      ...child.props,
      isSelected: selected === child.props.value,
      onClick: () => onSelected(child.props.value),
    }),
  )

const SmallMenu: React.FunctionComponent<ISmallMenuProps> = ({
  className,
  onSelected,
  selected,
  children,
}) => (
  <div className={clsx('dropdown', className)}>
    <button
      type="button"
      className={clsx('btn btn-primary dropdown-toggle', styles.menuButton)}
      data-toggle="dropdown"
    >
      <i className={clsx('material-icons', styles.dots)}>more_horiz</i>
    </button>
    <ScrollContainer
      className={clsx('dropdown-menu dropdown-menu-right', styles.menu)}
    >
      {React.useMemo(
        () => hookChildren(children, selected, onSelected),
        [children, onSelected, selected],
      )}
    </ScrollContainer>
  </div>
)

export default SmallMenu
