import React from 'react'
import uniqueId from 'lodash/uniqueId'

import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import { Theme } from '@material-ui/core/styles'

import Checkbox from '@material-ui/core/Checkbox'

interface CheckBoxProps {
  selected: boolean
  status: string
  onClick: () => void
}

const StyledCheckBox = withStyles(({ palette }: Theme) =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    checked: {
      color: palette.secondary.main,
    },
  }),
)(Checkbox)

const CheckBox: React.FC<CheckBoxProps> = ({ selected, status, onClick }) => {
  const inputId: string = React.useMemo(uniqueId, [])

  return (
    <StyledCheckBox
      id={inputId}
      checked={selected}
      onChange={onClick}
      disabled={status === 'cancelled'}
    />
  )
}

export default CheckBox
