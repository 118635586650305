import * as React from 'react'
import clsx from 'clsx'
import first from 'lodash/first'
import keys from 'lodash/keys'

import ScrollContainer from 'react-perfect-scrollbar'
import { Table, Column } from 'react-virtualized'

import { MappingNames } from '../../apps/ImportCSV/Context/Mappings'
import { IShipment } from '../../apps/ImportCSV/Context/Shipments/typings'
import HeaderRow from './HeaderRow'

import styles from './VirtualShipmentTable.module.scss'

export interface IVirtualTableProps {
  height: number
  shipments: IShipment[]
}

const VirtualShipmentTable: React.FunctionComponent<IVirtualTableProps> = ({
  height,
  shipments,
}) => {
  const scrollSystemRef = React.useRef<any>(null)
  const scrollContainerRef = React.useRef(null)
  const tableRef = React.useRef<any>(null)
  const headerRowRef = React.useRef<HeaderRow>(null)

  const handleGetRowValue = React.useCallback(
    ({ index }) => shipments[index],
    [shipments],
  )
  const handleContainerRef = React.useCallback((node) => {
    scrollContainerRef.current = node
  }, [])
  const columnNames = React.useMemo(
    () => keys(first(shipments) as any),
    [shipments],
  )

  const handleHorizontalScroll = React.useCallback((container: HTMLElement) => {
    const { current: row } = headerRowRef

    row!.setScrollLeft(container.scrollLeft)
  }, [])

  const handleVerticalScroll = React.useCallback((container: HTMLElement) => {
    const {
      current: { Grid: grid },
    } = tableRef

    // eslint-disable-next-line no-underscore-dangle
    grid._onScroll({ target: container })
  }, [])

  React.useEffect(() => {
    const { current: scrollSystem } = scrollSystemRef
    const {
      current: { Grid: grid },
    } = tableRef
    const { current: scrollContainer } = scrollContainerRef

    // eslint-disable-next-line no-underscore-dangle
    grid._scrollingContainer = scrollContainer
    scrollSystem!.updateScroll()
  }, [])

  return (
    <>
      <HeaderRow ref={headerRowRef} columns={columnNames} />

      <ScrollContainer
        ref={scrollSystemRef}
        className={styles.scrollContainer}
        style={{ height: height - 71 }}
        containerRef={handleContainerRef}
        onScrollY={handleVerticalScroll}
        onScrollX={handleHorizontalScroll}
      >
        <Table
          disableHeader
          className={styles.table}
          ref={tableRef}
          gridClassName={styles.grid}
          height={height - 71}
          headerHeight={70}
          headerRowRenderer={null as any}
          rowHeight={45}
          rowCount={shipments.length}
          rowGetter={handleGetRowValue}
          rowClassName={styles.row}
          width={400 + 250 * (columnNames.length - 1)}
        >
          {React.useMemo(
            () =>
              columnNames.map((columnName) => (
                <Column
                  width={250}
                  key={columnName}
                  label={columnName}
                  dataKey={columnName}
                  className={clsx(
                    styles.cell,
                    columnName === MappingNames.Address && styles.address,
                  )}
                />
              )),
            [columnNames],
          )}
        </Table>
      </ScrollContainer>
    </>
  )
}

export default VirtualShipmentTable
