import * as React from 'react'
import Color from 'color'
import clsx from 'clsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SmallMenu from '../../../../../../components/SmallMenu'
import SmallMenuItem from '../../../../../../components/SmallMenu/Item'

import { IMapping } from '../../../../Context/Mappings/typings'

import styles from './MappingCard.module.scss'

export interface IMappingCardProps {
  mapping: IMapping
  columns: string[]
  onChangeMapping: (mapping: IMapping) => void
}

const MappingCard: React.FunctionComponent<IMappingCardProps> = ({
  mapping,
  columns,
  onChangeMapping,
}) => {
  const { name, color } = mapping

  const isDark = React.useMemo(() => Color(color).isDark(), [color])
  const handleOnSelected = React.useCallback(
    (selectedIndex: number) =>
      onChangeMapping({
        ...mapping,
        index: mapping.index === selectedIndex ? undefined : selectedIndex,
      }),
    [mapping, onChangeMapping],
  )

  return (
    <div
      className={clsx('card', styles.card)}
      style={{ backgroundColor: color }}
    >
      <div className={clsx(styles.content, isDark && styles.light)}>
        <div className={styles.iconContainer}>
          {mapping.required && mapping.index === undefined && (
            <span
              data-toggle="tooltip"
              data-placement="right"
              title="This is a required field to create shipments."
            >
              <FontAwesomeIcon className={styles.icon} icon="asterisk" />
            </span>
          )}
          {mapping.index! >= 0 && (
            <span
              data-toggle="tooltip"
              data-placement="right"
              title={`Assigned to: ${columns[mapping.index!]}`}
            >
              <FontAwesomeIcon className={styles.icon} icon="check" />
            </span>
          )}
        </div>

        <p className={styles.text}>{name}</p>
      </div>

      <SmallMenu selected={null} onSelected={handleOnSelected}>
        {columns.map((columnName, columnIndex) => (
          <SmallMenuItem key={columnName} value={columnIndex}>
            <p className={styles.text}>{columnName}</p>
          </SmallMenuItem>
        ))}
      </SmallMenu>
    </div>
  )
}

export default MappingCard
