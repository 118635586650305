import React from 'react'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import classes from './ProgressIndicator.module.scss'

interface ProgressIndicatorProps {
  progressText: string
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  progressText,
}) => {
  return (
    <Backdrop open className={classes.back_drop}>
      <CircularProgress size={72} color="inherit" />
      <p className={classes.loading_text}>{progressText}</p>
    </Backdrop>
  )
}

export default ProgressIndicator
