import { createAction, union, props } from '../../../../utils/actions'
import { Timeslot } from './typings'

interface FailureResult {
  error: Error
}

export const createShipment = createAction('CREATE_SHIPMENT')
export const createShipmentSuccess = createAction('CREATE_SHIPMENT_SUCCESS')
export const createShipmentFail = createAction(
  'CREATE_SHIPMENT_FAIL',
  props<FailureResult>(),
)
export const fetchDeliveryDate = createAction('FETCH_DELIVERY_DATE')
export const fetchDeliveryDateSuccess = createAction(
  'FETCH_DELIVERY_DATE_SUCCESS',
  props<{ timeslot: Timeslot[] }>(),
)
export const fetchDeliveryDateFail = createAction(
  'FETCH_DELIVERY_DATE_FAIL',
  props<FailureResult>(),
)
const all = union({
  createShipment,
  createShipmentSuccess,
  createShipmentFail,
  fetchDeliveryDate,
  fetchDeliveryDateSuccess,
  fetchDeliveryDateFail,
})
export type ManualShipmentActions = typeof all
