import React from 'react'

import { MuiThemeProvider } from '@material-ui/core/styles'

import theme from '.'

export interface Props {
  children: React.ReactNode
}

const ThemeProvider = ({ children }: Props) => (
  <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
)

export default ThemeProvider
