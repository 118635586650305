import * as React from 'react'
import clsx from 'clsx'

import DialogBase from '../../../../../../components/DialogBase'
import TrunkrsLogo from '../../../../../../components/TrunkrsLogo'

import styles from './LoadingDialog.module.scss'

export interface ILoadingDialogProps {
  open: boolean
}

const LoadingDialog: React.FunctionComponent<ILoadingDialogProps> = ({
  open,
}) => (
  <DialogBase open={open}>
    <div className={clsx('modal-content', styles.modal)}>
      <div className={clsx('modal-body', styles.body)}>
        <div className={styles.logoContainer}>
          <TrunkrsLogo className={styles.logo} />
        </div>
        <h6 className={styles.text}>Preparing your shipment preview...</h6>
      </div>
    </div>
  </DialogBase>
)

export default LoadingDialog
