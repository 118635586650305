import React from 'react'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'

import NotificationType from '../../constants/NotificationType'

interface NotificationBarProps {
  message: string
  duration: number
  onClose: () => void
  severity: string
}

type WorkingAlertProps = AlertProps & { children: React.ReactNode }
const Alert = (props: WorkingAlertProps) => {
  return <MuiAlert variant="filled" {...props} />
}

const NotificationBar: React.FC<NotificationBarProps> = ({
  message,
  duration,
  onClose,
  severity,
}) => (
  <Snackbar open autoHideDuration={duration} onClose={onClose}>
    <Alert
      variant="filled"
      onClose={onClose}
      severity={
        severity === NotificationType.Success
          ? NotificationType.Success
          : NotificationType.Error
      }
    >
      {message}
    </Alert>
  </Snackbar>
)

export default NotificationBar
