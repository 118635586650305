import React from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'

import classes from './DeliveryDateFilter.module.scss'

export interface Props {
  selectedDate: Date | any
  handleDateChange: (date: Date | any) => void
}

const DeliveryDateFilter: React.FC<Props> = ({
  selectedDate,
  handleDateChange,
}) => (
  <KeyboardDatePicker
    autoOk
    disableToolbar
    label="By Delivery date"
    variant="inline"
    inputVariant="outlined"
    className={classes.date_picker}
    format="DD-MM-YYYY"
    value={selectedDate}
    onChange={handleDateChange}
  />
)

export default DeliveryDateFilter
