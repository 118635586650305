import React from 'react'
import Link from '@material-ui/core/Link'

import Typography from '@material-ui/core/Typography'
import ButtonBase from '@material-ui/core/ButtonBase'

import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import classes from './DepricationMessage.module.scss'
import Rocket from './RocketImage'

export interface DepricationMessageProps {
  googleForm: string
}

export const DepricationMessage: React.FC<DepricationMessageProps> = ({
  googleForm,
}) => {
  const [t] = useTranslation('CSV_UPLOAD')

  const [hide, setHide] = React.useState(false)

  const handleHideMessage = React.useCallback(() => {
    setHide(!hide)
  }, [hide])

  return (
    <>
      <div className={clsx(classes.root, hide && classes.hide)}>
        <Rocket className={clsx(classes.rocket)} />
        <div className={clsx(classes.content, hide && classes.hide)}>
          <Typography className={clsx(classes.header, hide && classes.hide)}>
            {t('Deprication.Upgrade')}
          </Typography>
          {!hide && (
            <Typography className={classes.text} variant="body1">
              {t('Deprication.Message')}
            </Typography>
          )}
          <div className={classes.buttons}>
            {!hide && (
              <Link
                href={googleForm}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.a}
              >
                <ButtonBase classes={{ root: classes.button }}>
                  {t('Deprication.Schedule')}
                </ButtonBase>
              </Link>
            )}
            <ButtonBase
              onClick={handleHideMessage}
              classes={{
                root: clsx(classes.buttonWhite, hide && classes.hide),
              }}
            >
              {!hide ? t('Deprication.Hide') : t('Deprication.Show')}
            </ButtonBase>
          </div>
        </div>
      </div>
      <div className={classes.spacer} />
    </>
  )
}

export default DepricationMessage
